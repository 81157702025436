.page-advices{
  .page-title{
    @include susy-media($window-mid){

      text-align: left;
    }
  }
}

.views-page-advices{
  .views-row{
    margin-bottom: 30px;
  }
}

.views-page-advices{
  @include susy-media($window-mid){

    .views-row{
      border-top: 1px solid #f0f0f0;

      &:first-child{
        border-top: none;
      }
    }
  }

  .views-actions{
    display: none;
  }
}

.b-advice{
  @include susy-media($window-mid){
    box-shadow: none;
    max-width: 100%;
    overflow: hidden;

    .b--content{
      margin-left: 260px;
    }

    .b--image{
      margin-top: 15px;
      margin-bottom: 20px;
      float: left;
      margin-right: 40px;
    }

    .b--title{
      text-align: left;
    }

    .b--actions{
      display: none;
    }
  }
}