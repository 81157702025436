//   FRONT PAGE
// --------------

.page-wrapper{
  min-height: 100%;
  padding-bottom: $footer-height +20px;
}


//View News Front
.news-view{

&__in{
  padding: 20px;
}

  &__title{
    text-align: center;
  }

  &__actions{
    text-align: center;
  }

  &__link{
    @include action-btn-red(300px);
  }
}

.news-block{
  max-width: 370px;
  text-align: left;
  padding: 0 0 20px;
  margin: 0 auto 20px;
  background-color: #f7f7f7;

  &__title{
    margin: 13px 0;
    font-weight: 400;
    height: 26px;
    overflow: hidden;
  }

  &__text{
    margin: 1em 0;
  }

  &__in{
    padding: 0 20px;
  }

  &__images{
    img{
      display: block;
    }

    .link{
      display: block;
      width: 100%;
      height: 285px;
    }
  }

  &__link{
    display: none;
  }

  &__date{
    color: $c-grey-3;
    font-size: 12px;
    font-weight: 400;
  }

  svg{
    display: block;
    fill-rule: evenodd;
    height: 40px;
    width: 40px;
    margin: auto;
  }

  @include susy-media($window-mid){


    .news-block__images{
      .link{
        height: 304px;
      }
    }
  }
}


// CATALOG COMPANY

.catalog{
  @include susy-media( $window-mid ){
    position: relative;
    box-shadow: 0 2px 6.7px 0.4px rgba(0, 0, 0, 0.3);
  }

  &:after{
    @include susy-media( $window-mid ){
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      background-color: $c-grey-4;
    }
  }

  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    min-width: 300px;
    margin: auto;
  }

  a{
    text-decoration: none;
  }

  &__title{
    text-transform: uppercase;
    font-size: 24px;
    display: block;
    text-align: center;
    width: 300px;
    margin: auto;
    @include susy-media( $window-mid ){
      vertical-align: middle;
      width: 78%;
      text-align: right;
      display: inline-block;
    }
    @include susy-media( $window-lg ){
      font-size: 36px;
    }
  }

  &__head{
    position: relative;
    padding: 34px  0 20px;
    box-shadow: 0 5px 4.9px 0.2px rgba(0, 0, 0, 0.1);
    @include susy-media( $window-mid ){
      font-size: 0;
      height: 290px;
      padding: 108px 0;
      box-shadow: none;
    }
    @include susy-media( $window-lg ){
      padding: 92px 0;
    }
  }

  &__link{
    @include susy-media( $window-mid ){
      vertical-align: middle;
      display: inline-block;
      text-align: right;
      width: 22%;
    }
    @include susy-media( $window-lg ){
      padding-right: 0;
    }
  }

  svg{
    display: block;
    margin: 11px auto 0;
    fill: $c-main;
    fill-rule: evenodd;
    height: 40px;
    width: 40px;
    @include susy-media($window-mid ){
      margin: 0 !important;
      fill: $c-action;
      transform: rotate(-90deg);
      padding-right: 12px;
      display: inline-block;
    }
  }

  .catalog__menu{
    background-color: $c-grey-4;
  }

}

.main-menu{
  text-align: center;
  padding: 10px 0 35px;
  @include susy-media( $window-xs ){
    @extend %clearfix;
  }

  @include susy-media( $window-mid ){
    padding: 38px 0 30px 35px;
    height: 290px;
    background: url('../images/bg-border-catalog.jpg') $c-grey-4 left top no-repeat;
  }

  @include susy-media($window-lg){
    padding: 38px 0 30px 69px;
  }


  &__item{
    @include susy-media( $window-xs){
      width: 49%;
      text-align: center;
    }
    @include susy-media($window-mid){
      text-align: left;
      width: auto;
    }
  }

  &__title{
    margin-top: 2px;
    width: 300px;
    font-size: 16px;
    line-height: 28px;
    text-transform: uppercase;
    font-family: $f-serif;
    display: block;
    margin: auto;
    text-align: center;
    margin-bottom: 7px;
    @include susy-media ( $window-mid ){
      width: auto;
      text-align: left;
      font-size: 24px;
      font-weight: 400;
    }
  }

  &__list{
    width: 300px;
    @include susy-media( $window-xs ){
      width: 100%;
    }
    @include susy-media( $window-mid ){
      padding: 15px 0 0 0 !important;
      width: auto;
      column-count: 4;
      column-gap: 0em;
      column-width: auto;
    }
  }

  &__link{
    width: 300px;
    background-color: $c-action;
    display: inline-block;
    line-height: 25px;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    height: 50px;
    margin: 5px 0;
    font-weight: 400;
    padding: 10px 0;
    text-transform: none;
    &.main-menu__link{
      @include susy-media( $window-mid ){
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: $c-grey-3;
        background-color: transparent;
        border-color: transparent;
        padding: 7px 0;
        margin: 0;
        height: auto;
        text-transform: none;
        text-align: left;
        width: auto;
        min-width: auto;
      }
    }
  }
}



//Gallary Front

.front-gallary{
  img{
    display: block;
    width: 100%;
  }

  svg{
    fill: white;
    fill-rule: evenodd;
  }

  .svg-logo{
    display: block;
    margin: auto;
    width: 80%;
  }

  .svg-logo-arrow{
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
  }

  &__row{
    display: table;
    width: 100%;
    overflow: hidden;
    background-color: $c-dark;
  }

  &__logo{
    display: block;
  }

  &__item{
    height: 100%;

    &_half{
      vertical-align: middle;
      display: table-cell;
      width: 50%;
    }
    &_alt{
      height: 90px;
      text-align: center;

      svg{
        height: 90px;
      }
      .front-gallary__logo{
        margin-right: 20px;
        vertical-align: middle;
        display: inline-block;
      }
      .front-gallary__link{
        vertical-align: middle;
        display: inline-block;
      }
    }
  }

  &__link{
    display: block;
  }
}
