.premium-gallary{
  font-size: 0;
  margin-bottom: 20px;
  @extend %clearfix;

  &__item{
    float: left;
    width: 50%;

    img{
      display: block;
      height: 100%;
      width: 100%;
    }

    &_wide{
      width: 100%;
    }
  }
  @include susy-media($window-mid){
    padding: 0 30px 20px;

    &__item{

      width: 48%;

      &_wide{
        width: 100%;
        padding-bottom: 3.5%;
      }

      &_last{
        float: right;
      }

    }
  }
}

.premium-video{
  margin: auto;

  .premium-video__content{
    position: relative;
    // padding-bottom: 56.25%;
    // height: 0;
    overflow:hidden;
    margin: auto;
    max-width: 560px;
    min-height: 315px;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      // height: 100%;
      @include susy-media($window-mid){
        max-width: 560px;
        max-height: 100%;
        height: auto;
        min-height: 315px;
      }
    }

    @include susy-media($window-mid){
      margin: 0 30px;
    }
  }

  &__title{
    border-top: 1px solid #f0f0f0;
    padding-top: 20px;
    display: block;
    font-family: $f-serif;
    text-align: center;
  }

  @include susy-media($window-mid){
    padding-bottom: 10px;

    &__title{
      margin-left: 0;
      margin-right: 0;
    }

    &__content{
      padding: 0 30px;
      max-width: 560px;
      margin: auto !important;
    }
  }
}

.premium-contact__title,
.premium-video__title{

}

.page-premium-position{

  .main-content{
    margin: 20px;
    box-shadow: 0 0 4.7px 0.4px rgba(0, 0, 0, 0.3);
    padding: 10px 0 0;

    &__text{
      padding: 0 20px;
    }

    @include susy-media($window-mid){
      padding-bottom: 15px;

      .page-title{
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 0;
      }

      &__text{
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }


}

.premium-contacts{

  &__title{
    padding-top: 10px;
    border-top: 1px solid #f0f0f0;
    display: block;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    font-family: $f-serif;
  }

  &__content{
    font-family: $f-serif;
    padding: 0 20px 0;
    text-align: center;
  }

  &__label{
    font-weight: 700;
  }

  &__group{
    padding-bottom: 20px;
  }

  &__link{
    text-decoration: none;
    color: $c-main;
  }

  @include susy-media($window-mid){

    &__title{
      margin-left: 0;
      margin-right: 0;
    }

    &__content{
      padding: 0 30px;
      text-align: left;
    }
  }
}