// BUSSINES PAGE

.views-page-event{

  .view-row{
    margin-bottom: 30px;
  }

  .b-event{

    &__image{
      width: 100%;
      height: 510px;
    }

    img{
      display: block;
    }
  }
}


.sign-up-course{
  padding: 15px 20px 20px;
  background-color: #3c9696;
  color: $c-light;
  font-size: 18px;
  text-align: center;

  a{
    color: $c-light;
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
  }

  &__text{
    display: block;
    margin-bottom: 10px;
  }


  &__link.sign-up-course__link{
    width: 260px;
    padding: 10px 0;
    text-align: center;
    display: inline-block;
    margin: auto;
    border: 2px solid white;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
  }

  @include susy-media($window-mid){
    padding: 30px 0;

    &__in{
      @extend %clearfix;
      width: 820px;
      margin: auto;
    }

    &__link{
      display: block;
      float: right;
    }

    &__text{
      font-size: 14px;
      float: left;
      padding: 15px 0;
      margin-bottom: 0;
    }
  }
}


.b-event{
  position: relative;
  &__image{
    width: 100%;
    height: 350px;

    img{
      width: auto;
      height: auto;
    }
  }

  &__title{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: $c-light;
    font-size: 30px;
    text-align: center;
    background-color: rgba(60, 150, 150, 0.8);
    padding: 7px 0;
  }

  @include susy-media($window-mid){
    &__image{
      height: 510px;
    }

    &__title{
      bottom: 40px;
    }
  }
}