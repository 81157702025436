// Page News
// ---------
.page-news{
  .page-title{
    text-align: center;
    @include susy-media($window-mid){
      text-align: left;
    }
  }
}

.views-item-blog,
.views-page-news{

  .view-content{
  }

  .b--link.js-img-fill img{
    height: auto;
    width: auto;
  }
  .views-row{
    padding: 0 20px 20px;
    @include susy-media($window-mid){
      margin-top: 10px;
    }
  }

  .b-block{
    max-width: 360px;
    margin: auto;
    box-shadow: 0 0 4.7px 0.4px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  .b--header{
    padding: 0 20px 16px;

    &.b--header{
      .b--link{
        height: auto;
      }
    }


    .b--title{
      margin: 9px 0;
    }
  }

  .b--half-left .b--link{
    overflow: hidden;
    display: block;
    text-decoration: none;
    color: $c-main;
    height: 300px;
  }

  .b--title{
    font-family: $f-serif;
    height: 34px;
    overflow: hidden;
    font-size: 24px;
    font-weight: 700;

    @include  susy-media($window-mid){
      margin: 0 0 10px;

    }
  }

  .b--date{
    font-size: 12px;
    color: $c-grey-3;
    font-weight: 400;
  }

  .b--half-left{
    padding-top: 6px;
  }

  .b--half-right{
    padding:  0 20px;
    @include susy-media($window-mid){
      padding: 0;
    }

    .b--title{
      display: none;
    }

    .b--text{
      padding-top: 8px;
      overflow: hidden;
      // height: 260px;

    }

    .b--date{
      display: none;
    }
  }

  .b--actions{
    margin: 20px 0 0;
    height: 50px;
    background-color: #f3f3f3;
    box-shadow: inset 0 5px 4.9px 0.2px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 12px 0;
  }
  svg{
    width: 100%;
    height: 100%;
  }

  .views-actions{
    text-align: center;
    .views-link{
      @include action-btn-red(320px);
    }
  }

  @include susy-media($window-mid){
    .views-title{
      text-align: left;
    }

    .views-row{
      padding: 0;
      margin-bottom: 33px;
    }

    .views-link{
      display: none !important;
    }

    .b-block{
      max-width: 100%;
      @extend %clearfix;
      box-shadow: none;
    }

    .b--header{
      display: none;
    }

    .b--half{
      @include span(6 of 12);

      &-right{
        @include span(6 of 12 last);

        .b--title{
          display: block;
          color: $c-main;
        }

        .b--link{
          text-decoration: none;
        }

        .b--date{
          display: block;
        }
      }
    }


    .b--actions{
      display: none;
    }
  }
}

.views-page-news{
  .b--half-left .b--link{
    @extend %image-scale;
  }
}

.red-icon{
  transform: rotate(180deg);
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: $c-action;
  padding: 4px 10px;

  svg{
    fill: $c-light;
  }
}

.node-type-blog{
  p{
    padding: 0 20px;
    margin: 20px 0;
  }

  @include susy-media($window-mid){
    p{
      padding: 0;
    }
  }
}