
$susy: (
  columns: 12,
  math: fluid,
  output: float,
  gutters: .25,
  gutter-position: inside,
  gutter-override: no-gutters,
  box-sizing: border-box,
  debug: (
    image: hide,
    color: rgba( blue, .20),
    output: background,
    toggle: top right,
  )
);





.main-content,
.footer__in{
  @include container;
}

// Media Queries XS and more
@include susy-breakpoint( $window-xs ){


  .service-price .carousel-item{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 512px;
    width: 100%;
    background: $c-dark url('../images/image-background-2.png') no-repeat;
    background-position: center center;

    &__logo{
      position: relative;
      width: 338px;
      margin: auto;
      z-index: 6;

      svg{
        fill: $c-light;
        width: 100%;
      }
    }

    &__actions{
      position: absolute;
      bottom: 110px;
      height: 0;
      left: 0;
      right: 0;
      display: block;
      margin: auto;
      text-align: center;
    }

    &__link{
      line-height: 47px;
      height: 50px;
      width: 240px;
      position: relative;
      display: inline-block;
      font-weight: 400;
      font-size: 18px;
      color: white;
      border: 2px solid $c-light;
      z-index: 5;
      text-decoration: none;
    }

    &:before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,.6);
    }
  }


  .front-gallary{
    display: none;
  }

  .main-menu{

    &__item{
      @include susy-breakpoint( $window-xs ($window-mid - 1)){
        @include span(6);
      }
    }
  }

}

// Media Queries SMALL and more
@include susy-breakpoint( $window-sm ){




  .news-block{
    margin: 0;
    padding: 0 0 30px;

    &__link{
      display: block;
      color: $c-action;
      font-size: none;
      &_icon{
        display: none;
      }
    }
  }


  .news-view{
    padding: 40px 0 30px;
    &__in{
      padding: 0;
      @extend %clearfix;
      $map1: layout( 3 0.0945 before);
      @include layout( $map1 );
    }

    &__item{
      margin-left: 2.9%;
      @include span(1);

      &:first-child{
        margin-left: 0;
      }
    }

    &__actions{
      margin-top: 40px;
    }

  }

}



// SUSY GRIDS SYSTEM
// -----------------

@include susy-breakpoint( $window-mid ){
  .header{
    @include layout( $susy );

    &__in{
      @include container($window-mid);
    }
  }

  .top-line{
    &__in{
      @include container($window-mid);
      @include susy-media($window-lg){
        @include container($window-lg);
      }
    }

    &__cell{
      @include span(50%);
    }
  }

  .header-top{
    @include container(100%);
    @extend %clearfix;

    &__cell{
      @include span(25%);

      &_wide{
        @include span(50%);
      }
    }
  }

  .logo{
    @include span(8 of 12);
    @include push(2 of 12);
  }

  .catalog{
    &__in{
      @include container(1180px);
    }

    &__head{
      @include span( 2.8 of 12);
    }

    &__menu{
      @include span( 9.2 of 12);
    }

  }
}//End of media queries MIDDLE




// PAGE CONTENT
// ------------



%container,
.top_ad,
.main-region-wrapper,
.footer_in,
.header__in{
  @include susy-media($window-mid){
    @include container($window-mid);
    padding-left: 0;
    padding-right: 0;
  }
  @include susy-media( $window-lg){
    @include container( $container-width );
  }
}



// FOOTER
// ------

.footer{
  &-main{
    @include susy-media( $window-mid){
      width: 100%;
    }
    @include susy-media($window-lg){
      @include span(4 of 7);
    }
  }

  &__in{
    @include susy-media( $window-mid ){
      @include container( 668px )
    }
    @include susy-media( $window-lg ){
      @include container( $window-lg);
    }
  }
}

.handheld{
  &__in{
    &_small{
      @include susy-media( $window-mid ){
        @include span( 2 of 5);
      }
    }
    &_big{
      @include susy-media($window-mid){
        @include span(3 of 5);
      }
    }
  }
}

.footer-aside{
  @include susy-media($window-lg){
    @include span(1.83 of 7);
    @include push(1.17 of 7);
  }
}

.footer-line{
  @include susy-media( $window-lg){
    clear: both;
  }
  &__cell{
    @include susy-media($window-lg){
      width: 50%;
      float: left;
    }
  }
}

.top-line-inner{
  &__in{

    @include susy-media($window-lg){
      @include container($window-lg)
    }
  }
}

// PAGE CATALOG COMPANY
// --------------------

.two-sidebars .main-content{
  @include susy-media($window-mid){
    @include span( 52.42% rtl);
    margin: 0 1.7%;
  }
}


.right-sidebar{
  @include susy-media($window-mid){
    @include span( 22.04% rtl );

    img{
      width: 100%;
      height: 100%;
    }
  }
}

.left-sidebar{
  @include susy-media($window-mid){
    @include span( 22.04% rtl last);

    img{
      width: 100%;
      height: 100%;
    }
  }
}


.views-aside-ad{
  margin-bottom: 12px;

  .view-content{
  @extend %clearfix;
  margin: -1.5%;

  @include susy-media($window-mid){
    margin: 0;
  }

  }

  .views-row{
    @include span( 47%);
    margin: 1.5%;
    @include susy-media($window-mid){
      width: 100%;
      float: none;
      margin: 0 0 20px 0;
    }
  }
}


// страница номинаций и премий

.carousel-partners{

  .b-our-partners{

    &__item{
      @include span( 50%);
    }
  }

  @include susy-media($window-mid){

    .b-our-partners{

      &__item{
        @include span( 25%);
      }
    }
  }
}

.carousel-partners-small{

  .b-our-partners{

    &__item{
      @include span(50%);
    }
  }
}

.write-to-us{
  @include susy-media($window-mid){

    &__cell{
      @include span(50%);
    }
  }
}

.wedding-reg{
  &__cell{
    @include susy-media($window-mid){
      @include span( 36.9%);
      margin-left: 7%;
    }

    &:first-child{
      margin-left: 0;
    }
  }

  &__text{
    display: block;
    clear: both;
  }
}

.sidebar-second{
  .main-content{
  }
}

.right-sidebar{

  .search{
    display: none;
  }

  .rubric-menu {
    display: none;
  }


  @include susy-media($window-mid){

    .search{
      display: block;
    }

    .rubric-menu{
      display: block;
    }
  }
}

.header-wedding-menu{
  display: none;

  @include susy-media($window-mid){
    display: block;
  }
}

.sidebar-second{
  @include susy-media($window-mid){
    .main-content{
      @include span(72.9%);
    }
  }
}

.node-type-blog{
  @include susy-media($window-mid){
    .right-sidebar{
      width: 23.8%;
    }
  }
}

// BOTTOM REGION

.bottom-region{
  @include susy-media($window-mid){
    @include container($window-mid);

    @include susy-media($window-lg){
      @include container($window-lg);
    }
  }
}

.yet-another-region{
  @include container(100%);
  @include susy-media($window-mid){
    @include container($window-mid)

    @include susy-media($window-lg){
      @include container($window-lg);
    }
  }
}

.b-blog-element{
  @include susy-media($window-mid){
    @include span(2 of 3);
  }
}

.page-type-gallary
.main-region-wrapper{
  @include container(100%);
  @include susy-media($window-mid){
    @include container(100%);
  }
  @include susy-media($window-lg){
    @include container(100%);
  }
}

// PAGE MAGAZINE
// -------------

.views-big-block-magazine{
  @include container(100%)

  @include susy-media($window-mid){
    @include container($window-mid)

    @include susy-media($window-lg){
      @include container($window-lg);
    }
  }
}

