// PAGE AGANCY
// -----------


.b-round{
  height: 120px;
  width: 120px;
  background-color: $c-competitors;
  border-radius: 50%;
  line-height: 22.5;

  &_diamond{
    padding: 0 36px;
  }

  &_people{
    padding: 0 34px;
    line-height: 21.7;
  }

  &_clock{
    padding: 0 36px;
    line-height: 21.7;
  }

  &_lock{
    line-height: 21.5;
    padding: 0 32px;
  }

  &_phone{
    line-height: 21.5;
    padding: 0 35px;
  }

  &_talk{
    padding: 0 34px;
    line-height: 20;
  }

  &_contract{
    padding: 0 30px 0 37px;
    line-height: 21.4;
  }

  &_project{
    padding: 0 30px 0 35px;
    line-height: 21;
  }

  &_list{
    line-height: 20.7;
    padding: 0 37px;
  }

  &_calendar{
    padding: 0 38px;
    line-height: 22;
  }
  &_wedding-day{
    line-height: 21;
    padding: 0 24px;
  }

  svg{
    height: 100%;
    width: 100%;
    fill: $c-light;
  }
}

.advantage{
  text-align: center;

  &__text{
    padding: 0 30px;
  }

  &__circle{
    margin-top: 40px;
  }
}

.b-circle{
  vertical-align: top;
  display: inline-block;
  width: 240px;
  text-align: center;
  margin-bottom: 30px;

  &__text{
    margin-top: 14px;
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
  }
  &__icon{
    margin: auto;
    display: block;
  }
}

.circle-algorithm{
  @include container(100%);
  padding: 25px 30px 90px 30px ;
  margin-top: 30px;
  border-top: 1px solid #f0f0f0;


  &__in{
    margin: auto;
    max-width: 560px;
    @include susy-media($window-mid){
      max-width: 682px;
    }
  }

  &__title{
    margin-bottom: 30px;
  }
}

.scheme-circle{
  height: 120px;

  &-1, &-3, &-5, &-7{
    text-align: left;
  }

  &-2, &-4, &-6{
    text-align: right;
  }

  .b-round{
    display: block;
  }
  &__in{
    display: inline-block;
    width: 120px;
  }

  &__title{
    margin: 10px -20px 0px;
    font-size: 18px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;

    &:before{
      content: '';
    }
  }
  @include susy-media($window-mid){
    margin-top: 10px;
    margin-bottom: 10px;

    &-1, &-4, &-7{
      text-align: center;
    }

    &-2, &-5{
      text-align: left;
    }

    &-3, &-6{
      margin-top: -120px;
      text-align: right;
    }
  }
}

.scheme-line{
  height: 1px;
  background-color: $c-competitors;
  width: 100px;
  margin: auto;

  &-1,&-3,&-5{
    transform: rotate(20deg);
  }

  &-2, &-4, &-6{
    transform: rotate(-20deg);
  }

  @include susy-media($window-mid){

    &-1, &-3, &-4, &-6{
      transform: rotate(-20deg);
    }

    &-2, &-5{
      margin-top: -70px;
      margin-bottom: 70px;
      transform: rotate(0);
      width: 320px;
    }

    &-1, &-4{
      margin-left: 140px;
    }

    &-3, &-6{
      margin-left: 388px;
    }

  }
}

.our-weddings{
  border-top: 1px solid #f0f0f0;
  padding-top: 30px;
}

.service-page,
.service-slider{
  padding: 0 20px;

  .carousel__item{
    position: relative;
    margin: auto;
    height: 400px;
    max-width: 320px;
    margin-bottom: 20px;
    box-shadow: 0 0 4.5px 0.5px rgba(0, 0, 0, 0.3);
  }

  .carousel__link{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
  }

  .carousel__image{
    height: 400px;
    max-width: 320px;

    img{
      width: auto;
      height: auto;
    }
  }

  &__link{
    display: none;
  }

  &__text{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    font-size: 24px;
    line-height: 30px;
    font-family: $f-serif;
    display: block;
    text-align: center;
    padding: 15px 20px;
    text-transform: uppercase;
    background-color: rgba(255,255,255,0.8);
    color: $c-main;

    a{
      color: inherit;
    }
  }

  img{
    display: block;
  }
}

.service-page{
  @include susy-media($window-mid){

    .carousel__item{
      max-width: none;
    }
    .carousel__image{
      max-width: none;
    }
  }
}

.service-slider{
  @include susy-media($window-mid){
    padding: 0;


    &:before,
    &:after{
      content: '';
      display: block;
      margin: 10px auto 0;
      clear: both;
      background-color: #c5c5c5;
      width: 80px;
      height: 3px;
    }

    &:before{
      margin-bottom: 30px;
    }

    .carousel__link{
      display: none;
    }

    .carousel__item{
      padding: 0 10%;

      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.3);
      }

      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 11;
      background: linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 24%, rgba(0,0,0,0) 76%, rgba(0,0,0,1) 90%, rgba(0,0,0,1) 100%); /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=1 );

      }
    }

    .carousel__item, .carousel__image{
      box-shadow: none;
      max-width: none;
      width: 100%;
    }

    .service-slider{

      &__text{
        background-color: transparent;
        text-transform: initial;
        font-size: 24px;
        position: relative;
        z-index: 20;
        color: $c-light;
        position: absolute;
        bottom: 100px;
        padding: 0;

        a{
          color: inherit
        }
      }

      &__actions{
        display: block;
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 30;
      }

      &__link{
        width: 240px;
        border: 2px solid $c-light;
        color: $c-light;
        padding: 12px;
        font-size: 18px;
        display: inline-block;
        transition: all 0.5s;

        @include susy-media($window-mid){
          &:hover{
            border-color: $c-action;
            color: $c-action;
          }
        }
      }
    }
  }

  @include susy-media($window-mid){

  }
}

.service-price{

  &__actions{
    text-align: center;
  }

  &__link{
    @include action-btn-red( 320px);
    margin: auto;
  }

  &__title{
    margin-bottom: 25px;
  }

  @include susy-media($window-mid){
    padding-top: 10px;
    border-top: 1px solid #f0f0f0;

    &__actions{
      display: none;
    }
  }
}

.owl-carousel .owl-item{

  img{
    width: auto;
  }


  @include susy-media($window-mid){
    float: left;
  }
}




.views-item-blog{
  .b--half-left{
    @include susy-media($window-mid){
      width: 210px;
      height: 210px;

      .b--link{
        height: 100%;
        width: 100%;
      }
    }
  }
  .b--half-right{

    .b--text{
      padding-top: 20px;

      p{
        margin: 20px 0;
      }
    }

    @include susy-media($window-mid){
      float: right;
      width: 360px;
      height: 210px;
      overflow: hidden;

      .b--date{
        padding: 15px 0 0;
      }

      .b--title{
        margin-bottom: 0;
      }
      .b--text{
        padding-top: 0;
        height: 153px;
      }
    }
    @include susy-media($window-lg){
      width: 530px;
    }
  }
  .views-item-blog-actions{
    display: none;
    @include susy-media($window-mid){
      display: block;
      text-align: right;

      .view-item-blog-link{
        @include action-btn-red;
      }
    }
  }
}


.yet-another-region{
  padding-top: 40px;
  padding-bottom: 40px;
  .widget-vk{
    display: none;
    @include susy-media($window-mid){
      display: block;
      float: right;
    }
  }
}