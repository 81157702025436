// Страница совета
// ---------------

.page-advice,
.page-advices{
  .main-content{
    padding-left: 20px;
    padding-right: 20px;
  }
}


.page-advice{

  .page-title{
    @include susy-media($window-mid){
      text-align: left;
    }
  }

  .main-content{
    box-shadow: block;
  }

  .body{
    clear: both;

    picture{
      display: block;
      margin-right: -20px;
      margin-left: -20px;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
}

.advice-photo{
  margin: 0 -20px -20px;
  @extend %clearfix;

  &__cell{
    float: left;
    width: 50%;
    height: 250px;
  }

  @include susy-media($window-mid){
    margin: 0;

    &__cell{
      height: 515px;
      width: 49%;
    }
  }

}

.advice-photo__cell_right{
  float: right;
}

.main-content{

  strong{
    font-family: $f-serif;
  }
}