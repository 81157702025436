//   Mixins
// ----------


%action-red{
  min-height: 50px;
  line-height: 26px;
  background-color: $c-action;
  color: $c-light;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  padding: 12px 20px;
  min-width: 220px;
  border: 1px solid $c-action;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;

  @include susy-media($window-mid){

    &:hover{
      color: $c-action;
      background-color: transparent;
      border: 1px solid $c-action;
      font-weight: 400;
    }
  }
  @extend %button-active;
}


@mixin action-btn-red($w: auto){
  @extend %action-red;
  width: $w;
}

%clearfix{
  &:before,
  &:after{
    content: ' ';
    display: table;
    clear: both;
  }
}

%transition{
  transition: all .5s;
}


%competitors-button{
  display: inline-block;
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  color: $c-light;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  background-color: $c-competitors;
  border-radius: 4px;
  @extend %button-active;
}


@mixin competitors-button($w:240px){
  @extend %competitors-button;
  width: $w;
}


%button-active{
  transition: all .5s;
  &:active{
    transition: all .5s;
    position: relative;
    bottom: -2px;
  }
}

%image-scale{
  img{
    transition: all 0.5s;

  }

  &:hover{

    img{
      transition: transform 3s;
      transform: scale(1.2);
    }
  }
}

@mixin slider-image-scale($duration: 5s){
  img{
    transition: all $duration;
    transform: scale(1.2);
  }
}


// DATA URL ICONS
// ---------------


$icon-arrow-right: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MC4xNSA0MC4xOCI+CiAgPHBhdGggZD0iTTE4MDQuOTgsMTYwNi44OGEwLjg3LDAuODcsMCwxLDEsMS4xOS0xLjI3bDkuMzEsOC42NWEwLjg3LDAuODcsMCwwLDEsLjA0LDEuMjNsLTAuMDIuMDNoMGwtOS4zMSw5LjMxYTAuODY5LDAuODY5LDAsMCwxLTEuMjMsMCwwLjg4MSwwLjg4MSwwLDAsMSwwLTEuMjRsOC42Ni04LjY3LTguNjQtOC4wNGgwWm01LjI1LTExLjc1YTIwLjExLDIwLjExLDAsMSwwLDE0LjIsNS44OSwxOS45ODYsMTkuOTg2LDAsMCwwLTE0LjItNS44OWgwWm0xMi45Nyw3LjEyYTE4LjM2LDE4LjM2LDAsMSwwLDUuMzcsMTIuOThBMTguMjksMTguMjksMCwwLDAsMTgyMy4yLDE2MDIuMjVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTc5MC4xNiAtMTU5NS4xMykiIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4K';
$icon-arrow-left: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MC4xNTYiIGhlaWdodD0iNDAuMTgiIHZpZXdCb3g9IjAgMCA0MC4xNTYgNDAuMTgiPgogIDxwYXRoIGlkPSJuYXYtYXJyb3ctbGVmdC5zdmciIGNsYXNzPSJjbHMtMSIgZD0iTTk2LjAxNywxNjA2Ljg4YTAuODY5LDAuODY5LDAsMSwwLTEuMTg4LTEuMjdsLTkuMzA2LDguNjVhMC44NjksMC44NjksMCwwLDAtLjA0MywxLjIzbDAuMDIxLDAuMDNoMGw5LjMwNiw5LjMxYTAuODc1LDAuODc1LDAsMSwwLDEuMjM1LTEuMjRsLTguNjY2LTguNjcsOC42NDItOC4wNGgwWm0tNS4yNDYtMTEuNzVhMjAuMSwyMC4xLDAsMSwxLTE0LjIsNS44OSwyMC4wMDYsMjAuMDA2LDAsMCwxLDE0LjItNS44OWgwWk03Ny44LDE2MDIuMjVhMTguMzU2LDE4LjM1NiwwLDEsMS01LjM3MiwxMi45OEExOC4yNzgsMTguMjc4LDAsMCwxLDc3LjgsMTYwMi4yNVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MC42ODggLTE1OTUuMTMpIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==';
$icon-arrow-down: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MC4xMjUgNDAuMDYzIj4KICA8cGF0aCBzdHlsZT0iZmlsbDogI2ZmZjsgZmlsbC1ydWxlOiBldmVub2RkIiBkPSJNMjA4LjUxNSw2NDUuNDUxYTAuODcsMC44NywwLDAsMSwxLjI3MywxLjE4NWwtOC42MzYsOS4yODdhMC44NywwLjg3LDAsMCwxLTEuMjMuMDQybC0wLjAyMi0uMDIsMCwwLTkuMjktOS4yODdhMC44NzIsMC44NzIsMCwwLDEsMS4yMzMtMS4yMzNsOC42NTMsOC42NDgsOC4wMjEtOC42MjRoMFptMTEuNzMsNS4yMzVhMjAuMDUzLDIwLjA1MywwLDEsMC01Ljg3NSwxNC4xNzUsMTkuOTcyLDE5Ljk3MiwwLDAsMCw1Ljg3NS0xNC4xNzVoMFptLTcuMTA5LDEyLjk0MmExOC4zMTgsMTguMzE4LDAsMSwwLTEyLjk0OCw1LjM2MUExOC4yMzksMTguMjM5LDAsMCwwLDIxMy4xMzYsNjYzLjYyOFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xODAuMTI1IC02MzAuNjU2KSIvPgo8L3N2Zz4K';
$icon-arrow-right-red: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MC4xNSIgaGVpZ2h0PSI0MC4xOCIgdmlld0JveD0iMCAwIDQwLjE1IDQwLjE4Ij4KICA8cGF0aCBzdHlsZT0iZmlsbDogI2RjMmExYjsgZmlsbC1ydWxlOiBldmVub2RkOyIgaWQ9Im5hdi1hcnJvdy1yaWdodC5zdmciIGNsYXNzPSJjbHMtMSIgZD0iTTE4MDQuOTgsMTYwNi44OGEwLjg3LDAuODcsMCwxLDEsMS4xOS0xLjI3bDkuMzEsOC42NWEwLjg3LDAuODcsMCwwLDEsLjA0LDEuMjNsLTAuMDIuMDNoMGwtOS4zMSw5LjMxYTAuODY5LDAuODY5LDAsMCwxLTEuMjMsMCwwLjg4MSwwLjg4MSwwLDAsMSwwLTEuMjRsOC42Ni04LjY3LTguNjQtOC4wNGgwWm01LjI1LTExLjc1YTIwLjExLDIwLjExLDAsMSwwLDE0LjIsNS44OSwxOS45ODYsMTkuOTg2LDAsMCwwLTE0LjItNS44OWgwWm0xMi45Nyw3LjEyYTE4LjM2LDE4LjM2LDAsMSwwLDUuMzcsMTIuOThBMTguMjksMTguMjksMCwwLDAsMTgyMy4yLDE2MDIuMjVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTc5MC4xNiAtMTU5NS4xMykiLz4KPC9zdmc+Cg==';


@mixin icon-arrow-right{
  background: url( $icon-arrow-right ) no-repeat;
  background-size: contain;
}

@mixin icon-arrow-left{
  background: url( $icon-arrow-left ) no-repeat;
  background-size: contain;
}

@mixin icon-arrow-down{
  background:transparent url( $icon-arrow-down ) left top no-repeat;
}

@mixin icon-arrow($position){

  $icon: $icon-arrow-right !default;

  @if ( $position == 'right' ){
    $icon: $icon-arrow-right;
  }

  @if ($position == 'down'){
    $icon: $icon-arrow-down;
  }

  @if ($position == 'left'){
    $icon: $icon-arrow-left;
  }

  @if ($position == 'top'){
    // $icon: $icon-arrow-down;
  }


  background:transparent url( $icon ) left top no-repeat;
  background-size: contain;
}

.icon-arrow-left{
  display: block;
   @include icon-arrow(left);
   height: 40px;
   width: 40px;
}

.icon-arrow-right{
  display: block;
  @include icon-arrow(right);
  height: 40px;
  width: 40px;
}

.icon-arrow-right-red{
  background:transparent url( $icon-arrow-right-red ) no-repeat;

}