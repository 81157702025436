// HEADER GLOBAL
// HEADER GLOBAL
// -------------

.header{
  z-index: 2;
  a{
    text-decoration: none;
    color: $c-main;
  }

  ul{
    list-style-type: none;
    font-size: 0;
    padding: 0;
    margin: 0;
    li{
      display: inline-block;
      font-size: 14px;
    }
  }
  &_front{

    a{
      color: $c-light;
    }
  }

  svg{
    height: 100%;
    width: 100%;
  }

  @include susy-media($window-mid){
    box-shadow: 0 0 10px 0.4px rgba(0, 0, 0, 0.2);
  }

  &_fair{
    .header-small-top{
      margin-bottom: 0;
    }

    .header-top{
      padding-bottom: 20px;
    }
  }
}

// HEADER
// ------

.header{
  background-color: $c-light;
  position: relative;

  &_front{
    // background: url(../images/img-background.jpg) no-repeat;
    background-color: black;
    background-position: center 50px;
    background-size: cover;
    color: $c-light;

  a{
    color: $c-light;
  }

    @include susy-media($window-mid){
      background-position: center top;

      svg{
        fill: $c-light;
      }
    }
  }
}



// HEADER SMALL TOP
// ----------------

.header-small-top{
  z-index: 5;
  height: 50px;
  background-color: $c-grey-top;
  position: relative;
  font-size: 0;
  position: relative;
  margin-bottom: 35px;
  box-shadow: 0.5px 0.9px 1px #dadada;

  &__phone{
    padding: 13px 19px;
    float: left;
    width: 60px;
    height: 100%;
    display: inline-block;
    border-right: 1px solid $c-grey-delimiter;
  }

  &__hamburger{
    border-left: 1px solid $c-grey-delimiter;
    float:right;
    height: 100%;
    width: 60px;
    padding-left: 7px;
  }


  svg{
      fill: $c-grey-text;
  }

  &__logo{
    padding-top: 14px;
    text-align: center;

    svg{
      width: 177px;
      height: 24px;
      fill: #323232;
    }
  }

  @include susy-media( $window-mid ){
    display: none;
  }
}



// TOP LINE
// --------

.header_front{
  .top-line{
  z-index: 5;
  position: relative;
  }
}

.top-line{
  display: none;

  @include susy-media($window-mid){
  display: block;
  font-size: 12px;
  height: 40px;
  background-color: #f1f1f1;
  box-shadow: 0 1px 1px #dadada;

  a{
    color: $c-grey-text;
  }

  svg{
    fill: $c-grey-text;
    height: 10px;
    width: 10px;
  }

  .social{
    svg{
      fill: $c-grey-text;
    }

    &__link{
      border-left: 1px solid #e3e3e3;

      &:first-child{
        border-left: none;
      }
    }
  }
  }
}

.header_front{
  padding-top: 1px;

  @include susy-media($window-mid){

    .top-line{
      margin-top: 36px;
      background-color: transparent;
      box-shadow: none;

      &__cell{
        &_right{
          text-align: right;
        }
      }
    }
  }
}

.header-phone{

  &__icon{
    margin-right: 6px;
  }

  &__link{
    color: $c-light !important;
  }
  svg{
    fill: $c-light;
  }
}

.header-address{
  svg{
    fill: $c-light;
  }
}


.header-contact{
  text-align: right;
  padding: 12px 0;

  span{
    display: inline-block;
  }

  &__group{
    display: inline-block;
    margin-left: 40px;
  }

  &__icon{
    margin-right: 5px;

    &_mail{
      svg{
        width: 13px;
      }
    }
  }
}

// HEADER TOP
// ----------


.header{
  &-top{
    display: none;

    @include susy-media($window-mid){
      display: block;
      padding: 20px 0 33px;

      &__cell{
        text-align: left;
      }

      &__cell_right{
        text-align: right;
      }
    }

    &__link.header-top__link{
      display: inline-block;
      min-height: 30px;
      background-color: $c-action;
      padding: 5px 10px;
      color: $c-light;
      text-transform: uppercase;
    }
  }

    &-inner-logo{
      width: 280px;
      height: 37px;
      margin: auto;
    }
}

.header_front{

  .header__in{
    height: 461px;
  }

  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-color: $c-dark;
    opacity: 0.4;
  }
  @include susy-media($window-mid){
    height: 930px;

    .header__in{
      height: auto;
    }
  }

  .header-top{
    position: relative;
    z-index: 5;
    font-size: 18px;
    text-align: center;
    display: block;
    margin: auto;
    padding: 5px 0;
    text-transform: uppercase;

  &__cell{
    text-align: left;

    @include susy-media($window-mid){
      text-align: left;
    }

    &_right{
      text-align: right;
    }
  }

  &__link{
    display: block;
    width: 300px;
    margin: auto;
    text-align: center;
    padding: 12px 0;

    @include susy-media($window-mid){
      display: inline-block;
      font-size: 14px;
      padding: 5px 10px;
      width: auto;
    }
  }



    .top-menu{
      @include susy-media($window-mid){
        margin: auto;
        width: 410px;

        .menu{
          display: flex;
          justify-content: space-between;
        }
      }



      .menu__item{
        display: block;
        font-size: 18px;

        @include susy-media($window-mid){
        display: inline-block;
         width:auto;
        vertical-align: top;
        }
      }

      .menu__link{
        margin: 20px auto;
        width: 300px;
        display: block;
        padding: 10px 0;
        border: 2px solid $c-light;

        @include susy-media($window-mid){
          padding: 5px 0;
          width: auto;
          border: none;
          font-size: 14px;
          margin: auto;
        }
      }
    }
  }

  .logo{
    display: none;
    @include susy-media($window-mid){
      position: relative;
      z-index: 5;
      display: block;
      margin-top: 405px;
    }
  }

  .svg-logo{
    @include susy-media($window-mid){
      width: 83%;
      display: block;
      margin: auto;
    }
  }
}





// FLOAT ELEMENT
// -------------

.float-element{
  position: relative;
  padding: 8px 0 80px;
  background-color: $c-action;
  color: $c-light;
  font-family: $f-serif;
  text-align: center;
  box-shadow: inset 0 0 9.5px 0.5px rgba(0, 0, 0, 0.3);

  svg{
    fill: $c-light;
    height: 40px;
    width: 40px;
    fill-rule: evenodd;
  }

  &__date{
    font-size: 30px;
  }

  &__text{
    display: inline-block;
    width: 300px;
    font-size: 18px;
    line-height: 1.35;
  }

  &__link{
    display: block;
    position: absolute;
    bottom: 14px;
    width: 100%;
  }

  &__number, &__month{
    line-height: 1.45;
  }

  @include susy-media( $window-xs){
    &__text{
      display: inline-block;
      width: 80%;
    }
  }


  @include susy-media( $window-mid ){
    z-index: 5;
    box-shadow: none;
    position: absolute;
    top: 719px;
    right: 73px;
    background-color: transparent;
    min-height: auto;
    padding-bottom: 0;

    svg{
      display: block;
      transform: rotate(-90deg);

    }

    &__number{
      font-size: 72px;
      display: block;
      line-height: 50px;

    }

    &__date{
      position: relative;
      top: 0;
      bottom: 0;
      padding: 16px 20px 10px 0;
      text-align: right;
      vertical-align: middle;
      display: inline-block;
      border-right: 5px solid $c-light;
    }

    &__text{
      width: 415px;
      font-size: 24px;
    }

    &__link{
      vertical-align: middle;
      padding: 0;
      display: inline-block;
      position: static;
      width: auto;
    }

    &__event{
      padding-left: 20px;
      padding-right: 20px;
      vertical-align: middle;
      text-align: left;
      display: inline-block;
    }
  }
}

// HEADER MENU
// -----------

.header-menu{
  display: none;

  @include susy-media($window-mid){
    position: relative;
    display: block;
    padding: 24px 0 46px;
    border-top: 1px solid #f1f1f1;
    background-color: $c-light;
    z-index: 5;
  }

  .menu{
    text-align: justify;
    text-align-last: justify;

    &__link{
      text-transform: uppercase;
      padding: 5px 10px;
    }
  }
}


// HEADER PHONE BLOCK


.header-inner-logo-fair{
  max-width: 367px;
  height: 90px;
  margin: auto;

}

.header_fair{
  .header-top{
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
  }
}

.header-wedding-menu{
  @include susy-media($window-mid){
    padding: 36px  0 46px;
    border-top: 1px solid #f1f1f1;
    text-align: justify;
    text-align-last: justify;
    text-transform: uppercase;

    .menu{
      margin: auto;
      max-width: 860px;
    }
  }
}

.header_wedding{
  .header-small-top{
    margin-bottom: 0;
  }




  @include susy-media($window-mid)
    .header-inner-logo{
      height: 62px;
    }
}

.header-school.header{
  height: 500px;
  background-color: black;

  .logo{
    display: none;
  }



  @include susy-media($window-mid){
  min-height: 932px;
  height: 100vh;

    svg{
      fill: $c-light;
    }

    .top-line{
      background-color: transparent;
      box-shadow: none;
      color: $c-light;

      .social{
        &__link{
          border-left: none;
        }
      }

      .header-contact{
        &__link{
          color: $c-light;
        }
      }


    }

    .header-menu{
      border-top: 1px solid white;
      background-color: transparent;

      .menu{
        &__link{
          color: $c-light;
        }
      }
    }

    .logo{
      display: block;
      margin-top: 220px;
      height: 230px;
    }

    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      background-color: $c-dark;
      opacity: 0.4;
    }
  }

}


.main-header-slider{
  background-color: $c-grey-3;
    position: absolute;
    z-index: -1;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;

  .header-carousel{
    &__item{
      position: relative;
      height: 100vh;
      width: 100vw;
      z-index: 1;

      img{
        display: block;
      }

    &:after{
      content: '';
      display: block;
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background-color: rgba(0,0,0,0.4);
      }
    }
  }
}


.page-type-school{


  .float-element{
    @include susy-media(max-width 919px){

      background-color: #e1e1e1;
      box-shadow: inset 0 0 9.5px 0.5px rgba(0, 0, 0, 0.3);
      color: $c-main;
      padding: 10px 0;
      min-height: 30px;

      &__date{
        text-align: left;
        padding: 0 75px 0 20px;
        width: 100%;
      }

      &__event{
        padding-right: 75px;
        padding-left: 20px;
        text-align: left;
        width: 100%;
      }

      &__text{
        width: auto;
      }

      &__link{
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        left: auto;
        width: auto;

        svg{
          transform: rotate(-90deg);
          display: table-cell;

        }
      }

      svg{
        fill: $c-main;
      }
    }

    &__text.float-element__text{
      width: 265px;
    }
  }
}


// HEADER MAGAZINE
// ---------------

.header-magazine{

  .header-small-top{
    margin-bottom: 0;
  }

  .header-top{
    display: block;

    &__cell{
      display: none;
    }
  }

  .header-inner-logo{
    margin-top: 20px;
    height: 60px;
    margin-bottom: 20px;
  }

  .header-top__cell_right{
    overflow: hidden;
  }

  .header__in{
    border-top: 1px solid #f1f1f1;
  }

  .social{
    margin-top: 45px;
    width: 96px;
    text-align: justify;
    text-align-last: justify;
    margin-bottom: 18px;

    &__link{
      width: auto;
      height: auto;
    }

    &__icon{
      width: 20px;
      height: 20px;
      padding: 0;

      &_fb{
        width: 15px;
        height: 20px;
      }

      &__in{
        width: 18px;
        height: 18px;
      }
    }
  }

  .header-menu{
    @include susy-media($window-mid $window-lg){
      .menu__item{
        font-size: 11px;
      }
    }
  }

  @include susy-media($window-mid){
    .header-inner-logo{
      margin-top: 50px;
      width: 221px;
      height: 76px;
    }

    .header-inner-logo{
      margin-top: 0;
      margin-bottom: 0;
      height: auto;
    }

    .header-top{
      &__cell{
        display: block;
      }
    }

    .header-top{
      padding: 20px 0;
    }
  }
}

.b-header-magazine{
  float: right;
  overflow: hidden;
  width: 238px;
  color: black;

  &__image{
    float: left;
  }

  &__text{
    margin-top: 75px;
  }

  &__date{
    font-family: $f-serif;
    font-size: 18px;
    display: block;
  }

  &__link.b-header-magazine__link{
    color: $c-action;
    text-decoration: underline;
  }
}

.header_main-wedding{
    background: #ccc url(../images/img-header-wedding-main-bg.jpg) no-repeat;
    background-position: top 130px center;
    background-size: cover;
    height: 500px;


    .header-inner-logo{
      svg{
        height: 62px;
      }
    }

    .header-top{
      display: block;
      padding: 20px 0;
      background-color: white;
    }

  @include susy-media($window-mid){
    padding-top: 10px;
    background-position: center;
    min-height: 930px;
    height: 100vh;

    &:after{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background-color: rgba(0,0,0, 0.5);
      z-index: 1;
    }

    .top-line{
      position: relative;
      z-index: 2;
      background-color: transparent;
      border-bottom: none;
      box-shadow: none;

      &__in{
        border-bottom: 1px solid white;
      }
    }

    .header-wedding-menu{
      position: relative;
      z-index: 2;

      .menu{
        &__link{
          color: white;
        }
      }
    }

      .social{
        &__link{
          border-left: none;
        }

        svg{
          fill: white;
        }
      }

      .header-contact{
        &__link{
          color: white;
        }



        svg{
          fill: white;
        }

      }


    .header-top{
      position: relative;
      z-index: 2;
      background-color: transparent;

      svg{
        fill: white;
      }
    }
  }
}

.page-agancy{
  .float-element{

    @include susy-media( max-width 919px){
      padding: 10px 0;
      background-color: #c8c8c8;

      &__event{
        text-align: left;
        padding-left: 40px;
        width: 80%;
      }

      &__text{
        display: inline-block;
        font-size: 30px;
        line-height: 40px;
        color: black;
      }

      &__link{
        width: 20%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg{
        transform: rotate(-90deg);
        fill: black;
      }
    }


    @include susy-media($window-mid){

      &__text.float-element__text{
        width: 250px;
      }
    }
  }
}

.header-address{

  &__value{
    vertical-align: top;
  }

  &__icon{
    margin-right: 6px;

    &_geo{
      vertical-align: middle;
      svg{
        height: 14px;

      }
    }
  }
}

//правки

.date-display-single{
  line-height: inherit;
}
