

.main-content{
  img{
    height: 100%;
    width: 100%;
  }
}


.node-type-blog{
  .page-title{
    text-align: left;
    margin-bottom: 0;
    text-transform: initial;
  }

  .main-content{
    padding-bottom: 20px;

    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 4.7px 0.4px rgba(0, 0, 0, 0.3);

    .blog-post-date{
      padding: 0 20px;
    }

    .page-title{
      margin-left: 20px;
      margin-right: 20px;
    }

    .main-content__text{
      margin-left: 20px;
      margin-right: 20px;
    }
    @include susy-media($window-mid){
      box-shadow: none;
      margin-left: 0;
      margin-right: 0;

      .main-content__text{
        margin-left: 0;
        margin-right: 0;
      }

      .blog-post-date{
        padding: 0;
      }

      .page-title{
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .main-region-wrapper{
    padding-bottom: 25px;
  }
}

.views-item-blog{
  .b--actions{
    margin: 20px -20px 0;
  }
}


.right-sidebar{
  padding-bottom: 40px;
  @include  susy-media($window-mid){

    .search{
      position: relative;

      input{
        background-color: transparent;
        color: $c-grey-text;
      }

      &__input{
        border: 1px solid $c-grey-text;
        &::-webkit-input-placeholder,
        &::-moz-placeholder{
          color: $c-grey-text;
        }
      }

      &__submit{
        background: transparent url('../images/icon-search.svg') no-repeat;
        background-position: center;
        height: 40px;
        width: 40px;
        min-width: auto;
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }
}

.rubric-menu{
  @include susy-media($window-mid){

    .menu{

      &__title{
        display: block;
        font-weight: 700;
        padding: 12px 0;
      }

      &__item{
        padding: 10px 0;
      }

      &__link{
        text-decoration: underline;
      }
    }
  }
}

.blog-post-date{
  color: $c-grey-text;
  display: block;
  margin-bottom: 15px;
}

.add-comment{
  &__actions{
    text-align: center;
    @include susy-media(500px){
      text-align: left;
    }
  }
}

.share,
.add-comment,
.add-comment-vk
{
  border-bottom: 1px solid #f0f0f0;
  padding: 35px 0;
}

.share{
  border-top: 1px solid #f0f0f0;
  font-size: 0;

  &__title{
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  svg{
    fill-rule: evenodd;
    height: 100%;
    width: 100%;
  }

  &__icon{
    display: inline-block;
    height: 31px;
    width: 31px;
    margin: 0 9px;

    &:first-child{
      margin-left: 0;
    }

    &_vk{
      svg{
        fill: #5b7fa6;
      }
    }
    &_fb{
      svg{
        fill: #1c64a9;
      }
    }
    &_in{
      svg{
        fill: #30618a;
      }
    }
    &_mail{
      svg{
        fill: #ffcc48;
      }
    }
    &_ok{
      svg{
        fill: #ff7d1b;
      }
    }
    &_twit{
      svg{
        fill: #00b8ea;
      }
    }
  }
}

.bottom-region{
  margin-bottom: 40px;
  padding: 0 20px;
  @include susy-media($window-mid){
    padding: 0;
  }
}

.metatags{
  padding: 25px 0;
  color: $c-grey-text;

  &__link{
    text-decoration: underline;
  }
}

.add-comment-vk__widget{
  img{
    width: 100%;
    height: 100%;
    max-width: 660px;
  }
}



// страница /blog

.content-head{
  padding-top: 1px;
}

.page-blog{

  .b-blog-element{
    @include susy-media($window-mid){
      width: 100%;
      .b--header{
        padding-left: 0;
        display: block;
      }

      .b--title{
        font-weight: 400;
      }

      .b--half-left{
          width: 100%;
          float: none;
          height: auto;

          > .b--link{
            height: 791px;
          }
      }

      .b--half-right{
        float: none;
        width: 100%;
        height: auto;
        .b--title{
          display: none;
        }
        .b--date{
          display: none;
        }
      }
    }
  }
}

.block-views-blog-block-1 .views-item-blog-actions{
  @extend %container;
  width: 1180px;
  text-align: center !important;
}