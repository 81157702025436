// Page Catalog company
// --------------------


  .b-action-red.company-add{
    display: none;
  }

  .b-action-red.company-add_left{
    display: block;

    .b-action-red__icon{
      @extend .icon-arrow-right;
    }

    @include susy-media($window-mid){
      display: none;
    }
  }

.b-action-red{
  box-shadow: -5px 0 4.7px 0.4px rgba(0, 0, 0, 0.3);
  padding: 10px 40px 10px 40px;
  height: 60px;
  margin-bottom: 20px;
  background-color: $c-action;

    &__label{
      display: inline-block;
      color: $c-light;
      padding: 6px 0;
      text-transform: uppercase;
      font-size: 18px;
    }

    &__icon{
      float: right;
      display: inline-block;
      height: 40px;
      width: 40px;
    }

    svg{
      width: 100%;
      height: 100%;
      fill: $c-light;
      fill-rule: evenodd;
    }

  @include susy-media($window-mid){

    box-shadow: 0 0 4.7px 0.4px rgba(0, 0, 0, 0.3);
    display: block !important;
    padding: 30px 0 20px 0;
    margin-bottom: 20px;
    text-align: center;
    height: auto;

    &__icon{
      float: none;
      display: block;
      margin: auto;
      width: 60px;
      height: 60px;
    }


  }
}

.two-sidebars{
  .main-content{
    .page-title{
      font-size: 18px;
      @include susy-media($window-mid){
        margin-top: 0;
        line-height: 1;
        margin-bottom: 25px;
        font-size: 24px;
        text-align: left;
      }
    }
  }
}



.aside-menu{
  display: none;
  position: relative;
  &__icon{
    cursor: pointer;
    top: -49px;
    right: 5px;
    position: absolute;
    display: block;
    height: 40px;
    width: 40px;
    svg{
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .menu{
    padding: 0 20px;

    &__link{
      padding: 5px 0;
    }
  }

  @include susy-media($window-mid){
    display: block;
    margin-bottom: 20px;
    background-color: #f0f0f0;

    .menu{
      padding: 30px 20px;

      &__link{
        display: block;
        text-decoration: underline;
        padding: 10px 0;
      }
    }
  }

}

.widget-vk{
  display: none;
  @include susy-media($window-mid){
    display: block;
  }
}

.b-top-place{
  position: relative;

  img{
    height: 100%;
    width: 100%;
  }

  &__label{
    padding: 5px 14px 5px 10px;
    position: absolute;
    top: 20px;
    right: 0;
    display: block;
    color: $c-light;
    background-color: $c-action;
    text-transform: uppercase;

    &:before{
      content: '';
      display: block;
      height: 50px;
      width: 10px;
      position: absolute;
      top: 0;
      left: -10px;
      background: url(../images/icon-flag.png) no-repeat;
    }
  }
}

.views-catalog-company{
  .views-row{
    margin-bottom: 20px;
  }
}

.b-top-place__img_large{
  display: none;
  @include susy-media($window-mid){
    display: block;
  }
}

.b-top-place__img_small{
  @include susy-media($window-mid){
    display: none;
  }
}


.views-aside-ad{
  @extend %clearfix;
  @include layout($susy);

  .views-row{

    img{
      height: 100%;
      width: 100%;
    }

    @include susy-media($window-mid){
      &:nth-child(n+4){
        display: none;
      }
    }
  }
}

.left-sidebar{
  overflow: hidden;
  font-family: $f-main;



  @include susy-media($window-mid){
  overflow: initial;

  .b-action-red.company-add_left{
      display: none !important;
    }
  }
}

.l-slider-ad{
  position: relative;

  .owl-dots{
    width: 100%;
    position: absolute;
    bottom: 30px;
    text-align: center;
  }

  .owl-dot{
    height: 11px;
    width: 12px;
    margin: 0 4px;
    display: inline-block;
    border-radius: 50%;
    background-color: white;
    opacity: 0.5;

    &.active{
      background-color: #dc2a1b;
      opacity: 0.7;
    }
  }

  padding: 30px 0 0;

  &__in{
    @include container;

    @include susy-media($window-mid){
      @include container($window-mid);

      @include susy-media($window-lg){
        @include container($window-lg);
      }
    }
  }
}

.two-sidebars{
  .main-region-wrapper{
    max-width: 400px;
    margin: auto;
    @include susy-media($window-mid){
      max-width: $window-mid;
    }

    @include susy-media($window-lg){
      max-width: $window-lg;
    }
  }
}


// Каталог Компаний
// ----------------




// Premium views
.view-catalog-page{
  overflow: hidden;
  margin-left: -7px;
  margin-right: -7px;

    &.view-display-id-attachment_1{

      .premium{
        &:after,
        &:before{
          content: none;
        }
      }
    }

  .premium{
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;




    &:after{
      position: absolute;
      top: 10%;
      right: 0;
      content: 'Топ';
      text-transform: uppercase;
      color: white;
      padding: 4px 10px;
      width: 50px;
      background-color: $c-action;
      box-sizing: border-box;
      font-size: 14px;
    }

    &:before{
      content: '';
      position: absolute;
      right: 50px;
      top: 10%;
      border: 14px solid transparent;
      border-width: 14px 6px;
      border-top-color: $c-action;
      border-right-color: $c-action;
      box-sizing: border-box;
    }

    &.vip{

      &:after{
        width: 46px;
        height: 33px;
        content: '';
        background: {
          color: $c-action;
          repeat: no-repeat;
          image: url($icon-crown);
          position: left 9px top 6px;
          size: 45%;
        }
      }

      &:before{
        content: '';
        position: absolute;
        top: 10%;
        right: 41px;
        height: 33px;
        border-width: 16.5px 6px;
        border-color: $c-action;
        border-left-color: transparent;
      }
    }


    &.no-image{

      .views-field-field-premium-catalog-image{
        width: 100%;
        height: 173px;
        background-color: $c-grey-premium;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 50px;
        border: 1px solid #e0e0e0;
        vertical-align: middle;
        text-align: center;
      }
    }
  }
  @include susy-media($window-mid){
    .premium{
    }
  }
  @include susy-media($window-lg){
    .premium{
      overflow:hidden;
      height: 173px;
    }
  }
}




.no-premium{
  height: 240px;
  width: 50%;
  float: left;
  padding: 7px;
  margin-bottom: 14px;

  .views-field-field-premium-catalog-image{

    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.no-image{

    .views-field-field-premium-catalog-image{
      background-color: $c-grey-premium;
      border: 1px solid #e0e0e0;
      padding: 0 30px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  @include susy-media($window-mid){
    width: 33.30%;
    height: 200px;
  }
}


// блок добавления компаний
// ------------------------

.block-custom-str-adv{
  padding-top: 40px;
  @extend %clearfix;
  .view-str-adv{
    padding-bottom: 25px;
    .views-row{
      padding: 10px 10px;
      border-top: 1px solid $c-grey-premium;

      &:first-child{
        border-top: none;
      }

      &:before{
        content: '•';
        margin-left: -9px;
      }
    }
  }

  @include susy-media($window-mid){

    #adv-div{
      padding-top: 12px;
      float: left;
      width: 70%;
      padding-right: 20px;

      .views-row{
        padding: 24px 10px;
      }
    }
  }
}

#custom-str-adv-form{
  padding: 20px;
  box-shadow: 0 0 4.7px 0.4px rgba(0, 0, 0, 0.3);

  label{
    font-size: 14px;
  }

  input{
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #dc2a1b;
    line-height: 1;
    height: 30px;
    margin: 5px 0 15px;
    width:  100%;
    max-width: none;
  }

  .form-submit{
    margin-top: 15px;
    border: none;
    color: $c-action;
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    padding-left: 20px;
    text-align: left;
    @extend .icon-arrow-right-red;
    background-position: top right;
  }

  label{
    padding-left: 20px;
    color: $c-brown;
  }

  @include susy-media($window-mid){
    width: 29.5%;
    float: right;
  }
}

.views-aside-ad{
  margin-left: -7px;
  margin-right: -7px;

  @include susy-media($window-mid){
    margin-left: 0;
    margin-right: 0;
  }
}