.image-small{
  display: block;

  img{
    display: block;
    height: 100%;
    width: 100%;
  }

  @include susy-media($window-mid){
    display: none;
  }
}

.image-large{
  display: none;
  @include susy-media($window-mid){
    display: block;
  }

  img{
    display: block;
    height: 100%;
    width: 100%;
  }
}


.views-page-contest{

  .views-row{
    margin-bottom: 20px;

  }
}


  .b-contest{
    max-width: 400px;
    margin: auto;
    position: relative;
    font-family: 'Poiret';
    box-shadow: 0 0 9.5px 0.5px rgba(0, 0, 0, 0.2);

    .b--title{
      text-transform: uppercase;
      font-size: 36px;
      margin-top: 8px;
      margin-bottom: 24px;
    }

    .b--text{
      font-size: 24px ;
    }

    .b--delimiter{
      position: relative;
      display: block;
      height: 14px;
      width: 100%;
      background:transparent url('../images/svg/icon-heart.svg') no-repeat;
      background-position: center;
      width: 190px;
      margin: 0 auto 6px;

      &:before{
        position: absolute;
        right: 0;
        content: '';
        top:7px;
        display: block;
        height: 1px;
        width: 80px;
        border-bottom: 1px solid $c-light;
      }

      &:after{
        position: absolute;
        content: '';
        display: block;
        top:7px;
        height: 1px;
        width: 80px;
        left: 0;
        margin: auto;
        border-bottom: 1px solid $c-light;
      }
    }

    .b--text-content{
      padding: 0 0 14px 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(240, 149, 160, .64);
      color: $c-light;
      text-align: center;
    }
  }

  @include susy-media($window-mid){

    .b-contest{
      @include container($window-mid)

      .b--text-content{
        top:0;
        left: 50px;
        width: 380px;
        padding: 32px 0 0 0;
        transition: all 0.5s;

        @include susy-media($window-lg){
          transition: all 0.5s;
          width: 410px;
          left: 90px;
        }
      }

      .b--title{
        margin-bottom: 20px;
      }
    @include susy-media($window-lg){
      @include container($window-lg);
    }
    }
  }

.pager{
  @include susy-media($window-mid){
    margin-top: 51px;
    display: block;
  }
}

.page-node-8,
.page-contest{
  h1,
  .page-title{
    @include susy-media($window-mid){
      text-align: left;
    }
  }
}

.views-partners{
  margin-top: 40px;

  .view-content{
    font-size: 0;
    text-align: center;
    margin: 0 -16px;
    @include susy-media($window-mid){
      text-align: left;
    }
    @include susy-media($window-mid $window-lg){
      margin: 0 -30px;
    }
  }

  .views-row{
    display: inline-block;
    margin: 0 16px 20px;
    @include susy-media($window-mid $window-lg){
      margin: 0 30px 20px;
    }
  }

}

.b-partner{
  font-size: 14px;
  width: 265px;
  height: 340px;
  background-color: $c-light;
  box-shadow: 0 0 4.5px 0.5px rgba(0, 0, 0, 0.3);
  position: relative;

  .b{
    &--image{
      img{
        width: 100%;
        height: 100%;
      }
    }

    &--content{
      width: 226px;
      height: 150px;
      background-color: white;
      position: relative;
      bottom: 30px;
      margin: auto;
    }
  }
}

.views{
  &-page-competitors,
  &-partners{

    h1.page-title.page-title{

      @include susy-media($window-mid){
        margin-top: 40px;
        margin-bottom: 20px !important;
      }
    }
  }

  &-page-competitors{

    .page-title{
      @include susy-media($window-mid){
        margin-left: 20px;
      }
    }
  }
}
