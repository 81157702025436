//  FOOTER
// ------------------

.footer{
  &-contact{
    a{
      &:active, &:hover{
        color: $c-action;
      }
    }
  }
}

.footer-social__link{
  &:active, &:hover{
    svg{
      fill: $c-action;
    }
    .footer-social__icon{
      border-color: $c-action;
    }
  }
}

.footer-line__link{
  &:hover{
    color: $c-action;
  }
}


.pager .active{
  &:hover{
    color: $c-light
  }
}

.news-block__link,
.carousel-item__link,
.breadcrumbs__link,
.native-store__link,
.handheld__link,
.contact-group__link,
.footer-line__link,
.social__link,
.pager__link,
.header-top__link,
.menu__link{
  @extend %button-active;
}


//Blog submit btutton


.search{
  &__submit{
    &:active{
      position: absolute !important;
      top: 12px;
    }
  }
}

.metatags{
  &__link{
    &:hover{
      text-decoration: none;
    }
  }
}