//   Fonts
// ---------

@font-face {
    font-family: 'NimrodC';
    src: url('../fonts/Nimrod/Nimrod.eot');
    src: url('../fonts/Nimrod/Nimrod.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Nimrod/Nimrod.woff') format('woff'),
         url('../fonts/Nimrod/Nimrod.ttf') format('truetype'),
         url('../fonts/Nimrod/Nimrod.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NimrodC';
    src: url('../fonts/Nimrod/Nimrod.eot');
    src: url('../fonts/Nimrod/Nimrod.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Nimrod/Nimrod.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poiret';
    src: url('../fonts/Poiret/PoiretOne-Regular.eot');
    src: url('../fonts/Poiret/PoiretOne-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Poiret/PoiretOne-Regular.woff') format('woff'),
         url('../fonts/Poiret/PoiretOne-Regular.ttf') format('truetype'),
         url('../fonts/Poiret/PoiretOne-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ASL Ruble';
    src: url('../fonts/ASLruble/rouble.eot');
    src: url('../fonts/ASLruble/rouble.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ASLruble/rouble.woff') format('woff'),
         url('../fonts/ASLruble/rouble.ttf') format('truetype'),
         url('../fonts/ASLruble/rouble.ttf') format('opentype'),
         url('../fonts/ASLruble/rouble.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}