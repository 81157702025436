// Drupal element style
// --------------------


.tabs{
  ul{
    list-style-type: none;
    margin: 10px 0;
    padding: 20px 0;
  }
  li{
    display: inline-block;
    margin-right: 20px;
  }
  a{
    color: $c-main;
    text-decoration: none;

  &.active{
    padding: 5px 10px;
    background-color: $c-action;
    color: $c-light;
    }
  }
}


.element-invisible{
  display: none;
}

// Временные стили

.footer a.active{
  color: $c-light;
}

.header{
  a.actve{
    color: $c-action;
  }
}

ul.contextual-links.contextual-links.contextual-links{
  font-size: 10px;
  text-transform: uppercase;
}


.page-fair-reg{
  h1.page-title{
    @include susy-media($window-mid){
      text-align: left;
    }
  }
}

.content.clearfix{
  > ymaps{
    width: 100% !important;
    .ymaps-2-1-34-map{
      width: 100% !important;
    }
  }
}

.ajax-progress{
  @extend .animated;
  @extend .fadeIn;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: rgba(255,255,255, 0.5);

  div{
    marin: auto;
    background: transparent url('throbber.png') no-repeat;
    background-position: center center;
    background-size: contain;
    height: 20px;
    width: 220px;
  }
}

// fields with table
// -----------------

.field-type-tablefield{
  table{
    margin-top: 40px;
    width: 100%;
  }
}

.node-type-serv{
  .field-name-field-serv-carusel-image{
    margin: 0 -20px;

    @include susy-media($window-mid){
      margin: 0;
    }
  }
}

.ajax-progress{
  display: none;
}

span.flag-throbber{
  display: none;
}

.page-show-all.page-show-all{
}


.node-type-show .field-type-text-with-summary{
  @extend %container;
}

.views-page-portfolio.views-page-portfolio{
  margin-left: -20px;
  margin-right: -20px;

  @include susy-media($window-mid){
    margin-left: 0;
    margin-right: 0;
  }
}

// node star

.node-star.node-star{
  img{
    display: block;
    height: 100%;
    width: 100%;
  }
}

.link-black-red{
  color: black;
  transition: all .3s;

  &:hover{
    color: $c-action;
  }
}