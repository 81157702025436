// PAGE ARTICLES
// -------------


.page-articles{
  .main-content{
    margin-left: 20px;
    margin-right: 20px;
  }
}

.views-page-articles{
  margin-top: 35px;
  .views-row{
    margin-bottom: 20px;
  }
}

.b-article{
  padding: 1px 20px 0;
  box-shadow: 0 0 4.7px 0.4px rgba(0, 0, 0, 0.3);

    svg{
      height: 100%;
      width: 100%;
    }

  .b{

    &--title{
      font-family: $f-serif;
      height: 34px;
      overflow: hidden;
      font-size: 24px;
      margin: 10px 0 10px;
      font-weight: 700;
    }

    &--date{
      display: none;
      @include susy-media($window-mid){
        display: block;
      }
    }


    &--actions{
      margin: 20px -20px 0;
      height: 50px;
      background-color: #f3f3f3;
      box-shadow: inset 0 5px 4.9px 0.2px rgba(0, 0, 0, 0.2);
      text-align: center;
      padding: 12px 0;
    }

    &--image{
      display: none;
      @include susy-media($window-mid){
        display: block;
      }
    }

    &--image-xs{
      margin: 0 -20px;
      @include susy-media($window-mid){
        display: none;
      }
    }

    &--tags{
      display: none;
      @include susy-media($window-mid){
        display: block;
      }
    }
  }

  @include susy-media($window-mid){
    border-top: 1px solid #f0f0f0;
    box-shadow: none;


    .b{

      &--title{
        margin-bottom: 0;
        font-weight: 400;
      }

      &--date{
        color: $c-grey-text;
        font-size: 12px;
        margin: 0 0 15px;
      }

      &--actions{
        display: none;
      }


      &--tags{
        color: $c-grey-text;
        font-size: 12px;
        margin: 30px 0 50px;
      }

      &--link{
        text-decoration: underline;
      }
    }
  }
}