.page-competitors{

  .page-title{
    @include susy-media($window-mid){
      text-align: left;
      margin-bottom: 40px;
    }
  }
}


.views-page-competitors{

  .view-content{
    font-size: 0;
    text-align: center;

    @include susy-media($window-mid){
      margin: 0 -20px;
      text-align: left;
    }
  }

  .read-all{
    text-align: center;
    a{
      @include action-btn-red;
    }
  }

  .views-row{
    vertical-align: top;
    display: inline-block;
    font-size: 14px;
  }


  .b-block{

    width: 260px;
    margin: 0 20px;
    @include susy-media($window-mid){
      margin: 0 20px 20px;
    }

    .b{


      &--text{
        background-color: $c-grey-5;
        border: 1px solid #f0f0f0;
        padding: 14px 0;
      }

      &--item{
        text-align: center;
        display: block;
        color: $c-dark;
      }

      &--voice{
        color: $c-grey-text;
        display: block;
        text-align: center;
        padding: 10px 0 0;
      }


      &--actions{
        text-align: center;
        padding: 20px 0;

        input{
          background-color: $c-competitors;
          border-radius: 4px;
        }
      }
    }
  }
}

.competitors-menu{

  box-shadow: 0 0 9.3px 0.7px rgba(0, 0, 0, 0.2);
  background-color: $c-grey-top;

  .menu{
    font-size: 0;
    padding: 20px 0 0px;
    margin: 0;
    text-align: center;

    &__item{
      display: block;
      margin: 0 auto 0;
      padding-bottom: 20px;
    }

    &__link{
      @include competitors-button;
    }

    @include susy-media($window-mid){
      @include container($window-mid);
      text-align: left;

      &__item{
        display: inline-block;
        text-align: center;
        margin: 0 70px 0 0;

        &:nth-child(3n){
          margin-right: 0;
        }
      }

      @include susy-media($window-lg){
        @include container($window-lg);
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
        &:after, &:before{
          display: none;
        }

        &__item{
          margin-right: 0;
        }
      }
    }
  }
}

.flag{
  width: 100%;
  max-width: 220px;
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 18px;
  height: 50px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
}

.flag-link-toggle{
  color: white;
  background-color: $c-competitors;

  @include susy-media($window-mid){

    &:hover{
      color: white;
      background-color: lighten($c-competitors, 10%);

  }


  }
}

.unflag-disabled{
  padding: 15px 0;
  font-size: 14px;
  background-color: #f0f0f0;
}
