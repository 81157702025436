/**********************************************
            SOCIAL BLOCK STYLES
**********************************************/

.social{
  font-size: 0;

  &__link{
    vertical-align: top;
    display: inline-block;
    height: 42px;
    width: 39px;
  }

  &__icon{
    display: block;
    padding: 11px;
    height: 100%;
    width: 100%;
  }

  svg{
    height: 100%;
    width: 100%;
    fill-rule: evenodd;
    fill: #999;
  }
}


  .social_footer{
    padding: 18px 0;


    .social__title{
      display: none;
    }

    .social__link{
      margin: 12px 22px 0;
      display: inline-block;
      height: 42px;
      width: 42px;
    }

    .social__icon{
      border: 2px solid $c-light;
      border-radius: 50%;
      padding: 8px;

      &_in{
        padding: 9px;
      }
    }


    svg{
      fill: white;
    }

    @include susy-media($window-mid){

      .social__link{
      }

      .social__icon{
        border: 0;
      }

      .social__title{
        display: block;
      }

      svg{
        fill: $c-grey-text;
      }
    }

    @include susy-media($window-lg){
      text-align: left;

      .social__in{
        margin-left: -22px;
      }

      .social__link{
        margin-left: 15px;
        margin-right: 0;
      }

    }
  }

  // Social in Header with inner pages
  // ---------------------------------

  .top-line-inner{

    .social{
      @include  susy-media($window-mid){
        .social__link{
          border-left: 1px solid #e5e5e5;

          &:first-child{
            border-left: 0;
          }
        }

        .social__icon{
          &_in{
            padding: 12px;
          }
        }
      }
    }
  }