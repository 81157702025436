// PAGE MAGAZINE
// -------------



.big-block-link{
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .owl-item{
    .big-block-link__link{
      opacity: 0;
      transition: all .3s;
    }

  }

  .active{
    .big-block-link__link{
      opacity: 1;
    }
  }



  svg{
    height: 100%;
    width: 100%;
    fill-rule:  evenodd;
    fill: white;
  }


  &__image{
    width: 100%;
    height: 350px;

    img{
      display: block;
      transition: none;
    }
  }

  &__link{
    display: inline-block;
    position: absolute;
    text-align-last: justify;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
  }

  &__icon{
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;

    width: 40px;
    height: 40px;
  }

  &__name{
    max-width: 80%;
    padding: 14px 0 14px 20px;
    font-weight: 400;
    color: white;
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    font-family: $f-serif;
  }

  @include susy-media($window-mid){
    margin-top: 40px;

    &__image{
      height: 810px;
    }

    &__link{
      padding-right: 70px;
      display: block;
      bottom: 90px;
      left: auto;
      background-color: rgba(255,255,255,0.95);

      svg{
        fill: black;
      }
    }

    &__icon{
      height: 30px;
      width: 30px;
    }

    &__name{
      max-width: none;
      padding-left: 40px;
      padding-right: 40px;
      color: $c-dark;
    }
  }

}

.widget-vk{
  max-width: 260px;
  float: right;

  @include susy-media($window-mid $window-lg){
    display: none;
  }
}


// Views-experts-block

.b-advice,
.b-expert{
  max-width: 360px;
  margin: auto;
  padding: 20px 20px 0;
  box-shadow: 0 0 4.7px 0.4px rgba(0, 0, 0, 0.3);

  img{
    width: auto;
    height: auto;
  }

  svg{
    height: 100%;
    width: 100%;
  }

  .b{
    &--image{
      margin: auto;
      height: 220px;
      width: 220px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 15px;
    }

    &--title,
    &--name{
      display: block;
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 24px;
      font-family: $f-serif;
    }


    &--actions{
      margin: 20px -20px 0;
      height: 50px;
      background-color: #f3f3f3;
      box-shadow: inset 0 5px 4.9px 0.2px rgba(0, 0, 0, 0.2);
      text-align: center;
      padding: 12px 0;
    }
  }
}

.views-expert-block,
.views-page-advices{

  .views-actions{
    display: block;
    text-align: center;
    .views-link{
      @include action-btn-red(260px);
    }

  }
}

.views-experts-block{
  padding-bottom: 30px;

  .views-row{
    padding: 0 20px 20px;
    vertical-align: top;
    @include susy-media($window-mid){
    }
  }

  @include susy-media($window-mid){
    padding-bottom: 0;

    .views-actions{
      display: none;
    }

    .b-expert{
      width: 220px;
      max-width: none;
      padding: 0;
      box-shadow: none;

      .b--actions{
        display: none;
      }

      .b--text{
        text-align: center;
      }
    }

    .views-row{
      display: inline-block;
      border-left: 1px solid #f1f1f1;
      padding: 0 50px;

      @include susy-media($window-mid $window-lg){
        padding: 0 62px;
      }
    }

    .views-row:first-child{
      padding-left: 0;
      border-left: none;
    }

    .views-row:last-child{
      padding-right: 0;
    }
  }
}

.fresh-magazine{
    width: 100%;
    background-color: black;

    @include susy-media($window-xs){
      padding: 40px;
    }
  &__in{
    @extend %clearfix;
    max-height: 290px;
    max-width: 400px;
    margin: auto;
    position: relative;
  }

  &__half{
    float: left;
    width: 50%;
    background-color: black;
    color: white;
    max-height: 290px;

    &_last{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 0;
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
    }
  }

  &__link{
    margin: auto;
  }

  &__image{
    max-height: 290px;

    img{
      width: initial;
      height: initial;
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__icon{
    margin: 15px auto 0;
    display: block;
    height: 40px;
    width: 40px;
    svg{
      height: 100%;
      width: 100%;
      fill: white;
      fill-rule: evenodd;
    }
  }

  &__text{
    width: 130px;
    margin: auto;
    text-align: center;
    display: block;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    @include susy-media($window-xs){
      font-size: 24px;
    }
  }

  @include susy-media($window-mid){
    display: none;
  }
}



.news-in-mobile{
  font-size: 24px;
  color: black;
  text-transform: uppercase;
  padding: 20px 20px 15px;
  text-align: center;

  &__text{
    display: block;
    margin: auto;
    max-width: 280px;
  }

  &__actions{
    margin-top: 20px;
    padding: 10px 30px 0;
    overflow: hidden;
    width: auto;
    margin: auto;
    max-width: 320px;
  }

  &__text{
    line-height: 1.2;
  }

  &__link{
    display: inline-block;
    float: left;

    &_apple{
      float: right;
    }
  }

  img{
    width: auto;
    height: auto;
  }

  @include susy-media($window-mid){
    display: none;
  }
}

// View archive magazine
// ---------------------

.view-magazine{
  font-size: 0;
  padding: 0 20px;
  margin: auto;

  .views-row{
    font-size: 14px;
    display: inline-block;
    width: 50%;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  @include susy-media($window-xs){
    width: auto;
    margin-left: -10px;
    margin-right: -10px;

    .views-row{
      width: 33%;
    }
  }

  @include susy-media($window-mid){
    padding: 0;
  }

  @include susy-media($window-lg){

    .views-row{
      width: 25%;
      padding: 0 10px;
    }
  }

}