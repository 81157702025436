.node-type-news{

  .page-title{
    @include susy-media($window-mid){
      text-align: left;
    }
  }
}

.meta.date{
  display: block;
  color: $c-grey-text;
  margin: 10px 0 20px 15px;
  @include susy-media($window-mid){
    margin-left: 0;
  }
}

.node-type-news{
  .main-content{

    img{
      max-width: 100%;
      height: 100%;
    }
    p{
      padding-left: 15px;
      padding-right: 15px;
    }
    @include susy-media($window-mid){
      p{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}