//   Global Style
// ----------------

*{
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

*{
  &:active{
    outline: none;
  }
}


html,
body{
  height: 100%;
}

body{
  font-family: $f-main;
  line-height: 1.45;
  font-size: 14px;
}

.page-title, h1{
  font-size: 24px;
  text-transform: uppercase;
  font-family: $f-serif;
  font-weight: 400;
  text-align: center;
}

h2{
  font-weight: 400;
}

h3{
  font-size: 18px;
  font-weight: 700;
  color: $c-action;
  text-transform: uppercase;
}

h4{
  font-size: 18px;
  font-family: $f-serif;
}

h5{
  font-family: $f-serif;
  font-size: 14px;
  margin: 1em 0;
}

h1,h2,h3,h4,h5,h6{
  &.title_left{
    text-align: left;
  }

  &.title_center{
    text-align: center;
  }
}
p{
  font-family: $f-serif;
  color: $c-label;
  font-weight: 400;
  line-height: 1.45;
}

img{
  display: block;
}

span{
  line-height: 1.45;
}

input[type=text],
input[type=email],
input[type=password],
textarea{
  margin: 10px 0;
  height: 40px;
  width: 100%;
  line-height: 38px;
  border: 1px solid #bebebe;
  background-color: #f1f1f1;
  padding: 0 10px;
  max-width: 420px;

  &:active,
  &:focus{
    outline: none;
  }
}

textarea{
  resize: vertical;
  height: 150px;
}

input[type=submit]{
  @include action-btn-red;
}

label{
  display: block;
  font-size: 14px;
  color: $c-label;
}

.form-required,
.required{
  color: $c-action !important;
}

// Breadcrumbs

.breadcrumbs{
  display: none;
  @include susy-media($window-mid){
    @include container($window-mid)
    display: block;
    padding: 14px 0 14px;

    &__delimiter{
      margin: 0 3px;
    }
    a, &__link{
      text-decoration: none;
      &:hover{
        color: $c-main;
        text-decoration: none;
      }
    }
    a,
    &__link,
    &__delimiter,
    &__current{
      color: $c-grey-text;
      font-size: 12px;
    }
    @include susy-media($window-lg){
      @include container($window-lg);
    }
  }
}

.menu{
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.page-wrapper{
  min-width: 320px;
  overflow: hidden;
  padding-bottom: $footer-height + 40px;
  @include susy-media( $window-xs ){
    padding-bottom: $footer-height-xs + 40px !important;
  }
  @include susy-media( $window-sm ){
    padding-bottom: $footer-height-sm + 40px !important;
  }
  @include susy-media( $window-mid ){
    padding-bottom: $footer-height-mid + 40px !important;
  }
  @include susy-media( $window-lg ){
    padding-bottom: $footer-height-lg + 40px !important;
  }
  margin-bottom: -$footer-height;
  @include susy-media( $window-xs ){
    margin-bottom: -$footer-height-xs;
  }
  @include susy-media( $window-sm ){
    margin-bottom: -$footer-height-sm;
  }
  @include susy-media( $window-mid ){
    margin-bottom: -$footer-height-mid;
  }
  @include susy-media( $window-lg ){
    margin-bottom: -$footer-height-lg;
  }
}

.page-type-gallary{
  .page-wrapper{
    padding-bottom: $footer-height;
    @include susy-media( $window-xs ){
      padding-bottom: $footer-height-xs + !important;
    }
    @include susy-media( $window-sm ){
      padding-bottom: $footer-height-sm !important;
    }
    @include susy-media( $window-mid ){
      padding-bottom: $footer-height-mid !important;
    }
    @include susy-media( $window-lg ){
      padding-bottom: $footer-height-lg !important;
    }
  }
}


a{
  text-decoration: none;
  color: $c-grey-text ;


  @include susy-media($window-mid){

    &:hover{
      color: $c-action;
    }
  }
}



// pager
// -----


.pager{
  display: block;
  clear: both;
  margin-top: 15px;
  text-align: center;
  @extend %clearfix;

  &__item{
    margin: 0 4px !important;
    vertical-align: top;
    display: inline-block;


    &_dots{
      a{
        background-color: transparent;
        color: $c-pager;
      }
    }

    &_arrow{
      .pager__link{
        font-size: 27px;
        line-height: 24px;
      }
    }
  }

  .active, &__link{
    display: inline-block;
    font-size: 18px;
    font-family: $f-main;
    background-color: $c-pager;
    height: 30px;
    line-height: 26px;
    width: 30px;
    border-radius: 50%;
    color: $c-light;
    text-decoration: none;
  }

  .active{
    background-color: $c-action;
  }

  @include susy-media($window-mid){
    margin-top: 51px;
  }
}

.main-content{
  padding: 0 20px;

  ul{
    padding-left: 30px;
  }

  p{
    margin: 1em 0;
  }

  li{
    margin: 1em 0;
    font-family: $f-serif;
  }

  h1, h2, h3, h4, h5{
    margin: 1em 0;
  }

  @include susy-media($window-mid){
    padding: 0;
  }
}


.js-img-fill{
  img{
    display: block;
    transition: all 1s;
  }
}

.owl-stage-outer{
  overflow: hidden;
}

.js-pos{
  position: absolute !important;
  top: 50px !important;
}


// advertising
// -----------

.top_ad{
  padding-top: 30px;
}