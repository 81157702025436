// Главная страница выставки
// -------------------------

.carousel-show-title{

  &__item{

    .data-image{
      width: 100%;
      height: 400px;
      background-position: center!important;
      background-size: cover!important;
    }
  }


  @include susy-media($window-mid){

    &__item{

      .data-image{
        height: 660px;
      }
    }

    .float-element{
      opacity: 0;
      transition: all .700s;
    }

    .active{

      .float-element{
        opacity: 1;
      }
    }
  }
}


.wedding-show-title-image{
  position: relative;

  img{
    display: block;
  }

  @include susy-media($window-mid){
    height: 660px;
  }
}

.float-element_wedding-show{
  padding-bottom: 70px;

  .float-element{

    &__title{
      font-size: 30px;
    }

    &__date{
      display: block;
    }
  }

  @include susy-media($window-mid){

    top: 440px;
    text-align: left;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;



    .float-element{

      &__in{

      }

      &__title{
      }


      &__date{
        line-height: 1.45;
        border-right: none;
        font-size: 40px;
        padding: 0;
        text-align: left;
      }

      &__link{
        padding-left: 20px;
      }
    }
  }
}

.icon-event-arrow{
  display: inline-block;
  height: 40px;
  width: 40px;
  @include icon-arrow('down');

  @include susy-media($window-mid){
    @include icon-arrow('right');
  }
}

// views-wedding-show
// ---------------------

.view-id-show_front,
.views-wedding-show{

  .views-actions{
    clear: both;
    height: 160px;
    padding: 40px 30px;
    background-color: $c-brown;
    display: flex;
    align-items: center;
  }

  .wedding-show__link{
    display: block;
    margin: auto;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    padding: 6px 40px;
    border: 1px solid white;
  }

  @include susy-media($window-mid){

    .wedding-show__link{
      width: 480px;
      font-size: 14px;
      padding: 17px 40px;
    }
  }
}


// Страница  регистрации выставки
// -----------------------------

.wedding-reg{
    padding-left: 20px;
    padding-right: 20px;

  &__icon{
    margin-top: 15px;
    margin-bottom: 15px;

    svg{
      fill: $c-light;
    }

    &-element{
      display: block;
      margin: auto;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #f095a0;
      padding: 8px 20px;
    }
  }

  &__cell{
    padding-bottom: 10px;
    border-top: 1px solid #f0f0f0;

    .wedding-reg__input.wedding-reg__input{
      max-width: 100%;
    }

    &:first-child{
      border-top: none;
    }
  }

  &__text{
    color: $c-grey-text;
  }

  &__actions{
    padding: 10px 0;
    text-align: center;
  }

  &__submit{
  }

  @include susy-media($window-mid){
    padding: 0;

    &__icon{
      display: none;
    }

    &__cell{
      border: none;
    }

    &__group{
      margin-bottom: 21px;

      &:last-child{
        margin-bottom: 0;
      }
    }

    &__actions{
      text-align: left;
      padding-top: 40px;
    }
  }
}


.masonry-item{
  @extend %image-scale;
}

// block show-social
// -----------------


.show-social{
  display: none;

  @include susy-media($window-mid){
    display: block;

    width: 290px;
    padding-top: 30px;
    float: right;

    .block-title{
      font-size: 14px;
      margin: 1em 0;
    }

    .social-list{
      padding: 0;
      margin: 0;
      list-style-type: none;

      &__item{
        margin: 40px 0;

        &:first-child{
          margin-top: 0;
        }
      }

      &__icon{
        margin-right: 12px;
      }

      &__link{
        display: block;
        font-family: $f-main;
        border: 2px solid #969696;
        font-size: 24px;
        text-transform: uppercase;
        padding:  2px 20px 2px 50px;
        vertical-align: middle;

        &:hover{
          border-color: $c-action;
          color: $c-action;

          svg{
            fill: $c-action;
          }
        }
      }

      svg{
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px;
        height: 23px;
        width: 30px;
        fill: #969696;
      }
    }
  }
}

// Claim form
// ----------

.b-claim-form{
  clear: both;
  padding: 25px 0 0;
  width: 100%;
  max-width:  420px;
  margin: auto;
  .block-title{
    @extend .page-title;
    text-align: left;
  }

  .form-actions{
    clear: both;
    padding: 30px 20px;
  }


  @include susy-media($window-mid){
  clear: none;
  float: left;
  max-width: 480px;

  .webform-client-form{
    margin: 30px -20px;
  }

    .form-item{
      padding: 0 20px;
      width: 50%;
      float: right;

      &:first-child{
        float: left;
      }
    }
  }

  @include susy-media($window-lg){
    max-width: 680px;
  }
}

// masonry fix
// -----------

.main-content{

  .masonry-gallary{
    position: relative;

    img{
      width:  auto;
      height: auto;
    }
  }
}

.view-id-show_all{
  .b--actions{
    padding: 30px 0;
    text-align: center;
  }
}

.masonry-item{
  // position: absolute !important;
}

// page type show
// --------------

.node-type-show,
.page-show-all{
  .main-region-wrapper{
    max-width: 100%;
  }
}