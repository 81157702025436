// PORTFOLIO
// ---------

.views-page-portfolio,
.views-portfolio{

  .views-row{
    border-top: 1px solid #f0f0f0;
  }
}

.views-page-portfolio,
.b-portfolio{
  padding: 20px 0;

  .b--content{
    @extend %clearfix;
  }

  .b--image{
    @extend %image-scale;

    img{
      display: block;
    }
  }

  .b--actions{
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: center;
  }

  &:first-child{
    border-top: none;
  }

  .b--text{
    display: none;
  }

  .b--image{


    .b--text{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 20;
      box-shadow: inset 0 0 150px rgba(0, 0, 0, 0.5);
      transition: all 0.5s;
      opacity: 0;
      &:after{
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(33, 37, 38, 0.26);
      }
    }

    .b--title{
      position: relative;
      z-index: 21;
      display: block;
      margin: auto;
      font-family: $f-main;
      font-size: 30px;
      color: $c-light;
      text-transform: uppercase;
      text-shadow: 0 0 53px rgba(0,0,0, 0.59);
    }

    &:hover{

      .b--text{
        opacity: 1;
        transition: all 0.5s;
      }
    }
  }
}


.grid-sizer{
  width: 100%;
  @include susy-media(640px){
    width: 50%;
  }
  @include susy-media($window-mid){
    width: 33.33%;
  }
}

.masonry{


  &-item{
    display: block;
    float: left;
    width: 100%;
    height: 351px;


    img{
    }

    @include susy-media(640px){
      width: 50%;

      &.masonry-item_wide{
        width: 100%;
      }
    }
    @include susy-media($window-mid){
      width: 33.33%;
      &.masonry-item_wide{
        width: 66.65%;
      }
    }
  }
}
