// Owl Carousel
// ------------

// Keyframes animations

@keyframes image-animation{
  0%{
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
  }
  8%{
    opacity: 1;
    -webkit-transform: scale(1.05);
    -webkit-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }
  25% {
    opacity: 0;
    -webkit-transform: scale(1.1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes flip-animation{

  0%{
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
  }
  32%{
    opacity: 1;
    -webkit-transform: scale(1.05);
    -webkit-animation-timing-function: ease-out;
  }
  68%{
    opacity: 1;
    -webkit-transform: scale(1.1);
  }
  100%{
    opacity: 0;
    -webkit-transform: scale(1.1);
  }
}

// Flip slider css animation

%image-animation{
    -webkit-backface-visibility: hidden;
    -webkit-animation: flip-animation 12.5s linear  0s;
    -moz-animation: flip-animation 12.5s linear  0s;
    -o-animation: flip-animation 12.5s linear  0s;
    -ms-animation: flip-animation 12.5s linear  0s;
    animation: flip-animation 12.5s linear  0s;
}

*{
  margin: 0;
  padding: 0;
}

.flip-slider{
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;


  &__item{
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center !important;
    background-size: cover !important;
    opacity: 0;
    z-index: 0;
    transform: translatez(0);
  }

  .flip-animation{
    @extend %image-animation;
  }
}


// Owl-carousel's
// --------------

// slider project on front page

.projects{
  display: none;

  @include susy-media($window-xs){

    display: block;
    padding: 30px 0 0;

    &-carousel{
      .carousel-item{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 512px;
        width: 100%;
        background: $c-dark url('../images/image-background-2.png') no-repeat;
        background-position: center !important;

        &_second{
          background-position: 50% 20% !important;

          .carousel-item__logo{
            width: 400px;
            height: 100px;
          }
        }

        &_fourth{
          background-position: 50% 62% !important;

          .carousel-item__logo{
            width: 100%;
          }
        }

        &_third{

          .carousel-item__logo{
            width: 100%;
            height: 290px;

            svg{
              height: 240px;
            }
          }
        }

        &__logo{
          position: relative;
          width: 338px;
          z-index: 6;

          svg{
            fill: $c-light;
            fill-rule: evenodd;
            width: 100%;
          }
        }

        &__actions{
          position: absolute;
          bottom: 110px;
          height: 0;
          left: 0;
          right: 0;
          display: block;
          margin: auto;
          text-align: center;
        }

        &__link{
          line-height: 47px;
          height: 50px;
          width: 240px;
          position: relative;
          display: inline-block;
          font-weight: 400;
          font-size: 18px;
          color: white;
          border: 2px solid $c-light;
          z-index: 5;
          text-decoration: none;

          &:hover{
            border-color: white;
            background-color: #fff;
            color: $c-main;
          }
        }

        &:before{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0,0,0,.6);
        }
      }
    }
  }
}

.slider-ad,
.projects-carousel{
  .owl-nav{
    position: absolute;
    top: 50%;
    height: 0;
    left: 0;
    right: 0;
  }

  .owl-next,
  .owl-prev{
    height: 40px;
    width: 40px;
    opacity: .5;
    @extend %transition;

    &:hover{
      opacity: 1;
    }
  }

  .owl-prev{
    float: left;
    margin-left: 3.2%;
    @extend .icon-arrow-left;
  }

  .owl-next{
    @extend .icon-arrow-right;
    float: right;
    margin-right: 3.2%;
  }
}


.active {
  .carousel-item{
    &__logo{
      @extend .animated;
      @include fadeInDown;
    }

    &__actions{
      @extend .animated;
      @include fadeInUp;
    }
  }
}


// Slider ad in catalog company page
// ---------------------------------

.owl-carousel.owl-carousel.slider-ad{
  img{
    width: 100%;
    height: 100%;
  }
}


.slider-ad{
  .owl-nav{
    display: none;

    @include susy-media($window-mid){
      display: block;
    }
  }
}

// carousel partners
// ------------------

.carousel-partners{
  .owl-dots{
    margin-top: 20px;
    text-align: center;
  }

  .owl-item.owl-item{
    img{
      width: 100%;
    }
  }

  .owl-dot{
    height: 12px;
    width: 12px;
    display: inline-block;
    border: 1px solid black;
    border-radius: 50%;
    @extend %transition;
    margin: 10px 5px;

    &.active{
      background-color: black;
    }

    @include susy-media($window-mid){
      &:hover{
        background-color: black;
      }
    }
  }
}