// Страница калькулятор
// --------------------

.page-node {
  font-size: 12px;


  .input-calc{
    height: 30px;
    font-size: 12px;
    line-height: 1;
    width:  65px;
  }

  .more_input{
    width: 150px;
  }

  .block-responsive{
    overflow-x: auto;
    overflow-y: hidden;
  }

  .wedding-calc{
    min-width: 630px;

    &__value-wrap,
    &__one-wrap{
      width: 150px;
      padding-left: 20px;
    }

    .row_wrapper{
      &:nth-child(even){
        background-color: #f0f0f0;
      }
    }



    &__link{
      @extend .red-button_small;
      width: 100%;

      & + .table{
        display: none;
      }
    }

    .for_one{
      width: 70px;
    }

    &__value{
      width: 56px;
      margin-left: 10px;
    }

    .wedding-calc__sum{
      margin: 10px 0;
      height: 40px;
      background-color: #f1f1f1;
      font-size: 14px;
      line-height: 48px;
      font-weight: 700;
      text-align: right;
      padding: 0 40px;
    }

    .wedding-calc__value-wrap{
      position: relative;
      width: 150px;
    }
  }
  .link_new{
    img{
      height: auto;
      width: auto;
    }
  }


}




.cart_up,
.cart_down{
  display: block;
  position: absolute;
  right: 25px;
}

.cart_up{
  top: 4px;
}

.cart_down{
  top: 26px;
}

.triangle-down,
.triangle-up{
  height: 0;
  width: 0;
  display: block;
  border: 8px solid transparent;
  border-width: 8px 5px;
  margin: 2px;
}

.triangle-up{
  border-bottom-color: black;
}


.triangle-down{
  border-top-color: black;
}

.title-calc{
  @include susy-media($window-mid){

  }
}

.no-marker{
  list-style-type: none;
  font-size:  0;
}


.calc-header{
  display: flex;
  justify-content:  flex-start;
  font-size: 14px;
  background-color: rgb(150,150,150);
  color: white;

  &__item{
    padding: 0 10px;
    width: 100px;
    text-transform:  uppercase; ;
    font-family: $f-main !important;

    &_name{
      flex-grow: 1;
    }
  }
}


.row_itog{
  padding-left: 20px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: center;
  height: 40px;

  .field{
    width: 90px;
    flex-shrink: 0;

    &.title{
      flex-grow: 1;
    }
  }
}

.row_more{
  padding-left: 20px;
}

// .input-calc{
//   display: inline-block;
// }

.calc-row{
  display: flex;
  flex-flow: row-nowrap;
  justify-content:  flex-start;

  &__pay,
  &__mod,
  &__sum{
    width:  100px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__mod{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title{
    flex-grow: 1;
    align-items: center;
  }
}

.calc-row__title{
  display: flex;
  flex-flow: row-nowrap;
}

.red-button_small{
  padding: 5px 12px;
  color: white;
  font-weight: 400;
  background: $c-action;
  display: inline-block;
  text-align: center;
}

.title_list{
  flex-grow: 1;
  padding-left: 20px;
}

.more{
  margin-left: 20px;
  background: $c-action;
  padding: 5px 12px;
  color: white;

  &:hover{
    color: white !important;
  }
}

.page-node .input-calc.error{
  background-color: lighten( $c-action, 20%);
  border-color: $c-action;
}

.link_in{
  margin: 20px 0 20px 20px;
  clear: both;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  #calc_link{
    margin: 0 20px;
  }
}

.all_itog{
  padding: 10px 20px;
  float: left;
  background: $c-action;
  color: white;
  margin: 20px 30px 20px 20px;
}

.link_new{
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-items: center;

  a{
    margin-right: 15px;
  }

  img{
    display: inline-block !important;
    margin-right: 10px;
  }

}

.hide{
  display: none;
}
