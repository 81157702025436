.presentation{
  font-size: 0;
  padding: 40px 20px;
  border-bottom: 1px solid #f1f1f1;
  text-align: center;

  .page-title{
    text-align-last: center;
  }

  &__item{

    display: inline-block;
    position: relative;
    margin-bottom: 20px;
  }
  @include susy-media(760px $window-mid){
    &__item{
      margin: 0 40px 20px;
    }
  }
  @include susy-media($window-mid){
    padding-left: 0;
    padding-right: 0;
    text-align-last: justify;

  }
}

.b-presentation{
  overflow: hidden;

  &__content{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
  }

  &__title{
    margin: auto;
    padding: 8px 0;
    text-transform: uppercase;
    position: relative;
    z-index: 5;
    color: $c-light;
    text-align: center;
    text-align-last: center;
    background-color: #c83c50;
    font-size: 24px;
    width: 240px;

    &:hover{
      color: $c-light;
    }
  }

  &:after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .3);
  }

  img{
    height: 100%;
    width: 100%;
    transition: all 1s;
  }


  &:hover{
    img{
      @include susy-media($window-mid){
        transition: all 4s;
        transform: scale(1.2);
      }
    }
  }

  @include susy-media($window-mid $window-lg){
    width: 285px;

    &__title{
      font-size: 14px;
      width: 160px;
    }
  }

  @include susy-media($window-lg){
    width: 380px;
    height: 250px;

  &__title{
    width: 240px;
    font-size: 24px;
  }

  }
}

.nomination{
  @extend %clearfix;

  &__cell{
    padding: 0 20px;
  }

  &__item{
    border-bottom: 1px solid #f0f0f0;
    // list-style-image: url(../images/svg-sprites.svg#icon-ul-dots);
    list-style-type: none;
    font-size: 18px;
    padding: 9px 0;

    &:first-child{
      border-top: 1px solid #f0f0f0;
    }
  }

  &__list{
    margin: 0;
    padding: 0;
  }


  @include susy-media($window-mid){


    &__cell{
      @include span(50%);
      margin-right: 0;
      padding: 0 40px;

      &:first-child{
      border-right: 1px solid #f095a0;
      }
    }

    &__item{
      font-size: 14px;
      padding-right: 30px;
    }
  }
}

.nomination,
.presentation,
.b-partner-logos,
.write-to-us{
  padding-top: 40px;

  &__icon{
    display: block;
    margin: auto;
    height: 40px;
    width: 100px;
    text-align: center;
  }

  svg{
    width: 100%;
    height: 100%;
    fill: #f095a0;
    fill-rule: evenodd;
  }
}

.nomination{
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #f0f0f0;
  &__icon{
    width: 41px;
  }
}

.presentation{
  padding-bottom: 20px;
  &__icon{
    width: 46px;
  }
}

.b-partner-logos{
  padding-bottom: 40px;
  border-bottom: 1px solid #f0f0f0;

  &__icon{
    width: 64px;
  }
}

.write-to-us{
  padding-left: 20px;
  padding-right: 20px;

  &__icon{
    width: 41px;
  }

  &__text{
    display: block;
    color: $c-grey-text;
    padding-bottom: 15px;
  }

  &__actions{
    text-align: center;
  }

  &__input.write-to-us__input{
    max-width: 100%;
  }

  &__textarea{
    min-width: 100%;
  }

  @include susy-media($window-mid){


    &__cell{
      padding-right: 30px;
      padding-left: 30px;
    }



    &__group{
      margin: 0 0 22px;

      &_textarea{
        margin-bottom: 0;
      }
    }

    &__actions{
      text-align: left;
    }
  }
}

.b-our-partners{
  @extend %clearfix;
  padding: 30px 0;

  &__item{
    display: inline-block;
    img{
      margin: auto;
      width: 100%;
      height: 100%;
      max-width: 200px;
    }
  }

  @include susy-media($window-mid){
    width: auto;
    max-width: auto;
  }

  &__item{
    img{
      max-width: 225px;
    }
  }

}

.carousel-partners{
  &__item{
    overflow: hidden;
  }
}