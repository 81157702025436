//   Footer
// ----------

.footer{
  margin-top: -$footer-height;
  padding-top: 20px;
  height: $footer-height;
  background-color: $c-dark;
  color: $c-light;
  text-align: center;

  @include susy-media($window-xs){
    height: $footer-height-xs;
  }

  @include susy-media($window-sm){
    height: $footer-height-sm;
  }

  @include susy-media($window-mid){
    height: $footer-height-mid;

    a{
      &:hover{
        color: $c-action;
        text-decoration: none;
      }
    }
    svg{
      transition: all .5s;

      &:hover{
        fill: $c-action;
      }
    }
  }

  @include susy-media($window-lg){
    height: $footer-height-lg;
  }

  a{
    color: $c-light;
    text-decoration: none;

  }

  ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  h3{
    margin: 0;
    @include susy-media($window-lg){
      text-align: left;
    }
  }

  &-main{
    display: none;
    @include susy-media( $window-mid){
      display: block;
    }
  }

  &__in{
    @include susy-media( $window-mid ){
      padding: 35px 0 0 0;
    }
  }

  &-menu{
    @include susy-media( $window-mid ){
      clear: both;
      text-transform: uppercase;
      padding: 38px 0 45px 0;
    }

    .menu{
      @include susy-media( $window-mid ){
        columns: auto 3;
        text-align: left;
        font-size: 14px;
      }

      &__item{
        @include susy-media ( $window-mid ){
          padding: 15px 0;
        }
      }
    }
  }

  &-aside{
    ul{
      @include susy-media( $window-lg){
        text-align: left;
      }
    }
  }

  &-contact{
    @include susy-media($window-lg){
      padding-bottom: 46px;

      span{
        line-height: 1.55;
      }

      &__list.footer-contact__list{
        padding-top: 7px;
      }
    }
  }
}

.project-menu{
  display: none;
  @include susy-media( $window-lg ){
    display: block;
    padding-bottom: 65px;
  }
  .menu{

    &__list{
      padding-top: 3px;
    }

    &__link{
      @include susy-media( $window-lg){
        text-decoration: underline;
      }
    }

    &__item{
      @include susy-media($window-lg){
        padding: 14px 0;
      }
    }
  }
}


.handheld{
  @include susy-media( $window-mid ){
    overflow: auto;
    padding: 4px 0 60px 0;
    border-bottom: 1px solid #323232;
  }

  &__in{
    &_small{
      padding: 0 48px 0 0;

      .handheld__image{
        @include susy-media( $window-lg ){
          padding: 0 30px;
        }
      }
    }

    &_big{
      @include susy-media( $window-mid ){
        padding: 0 0 0 24px;
        @include susy-media($window-lg){
        background: url('../images/img-ipad.png') no-repeat;
        background-position: 25px top ;
        }

        .handheld__image{
           img{
             bottom: 31px;
             right: 14px;
           }
        }

        .handheld__text{
          @include susy-media( $window-mid ){
            padding: 0 40px;
          }
        }
      }
    }
  }

  &__image{
    @include susy-media( $window-mid ){
      display: none;
    }
    @include susy-media( $window-lg ){
      display: block;
      position: relative;
      height: 450px;
      margin-bottom: 24px;
    }

    img{
      @include susy-media( $window-mid ){
        position: absolute;
        bottom: 0;
      }
    }
  }

  &__text{
    @include susy-media( $window-mid ){
      display: block;
      text-transform: uppercase;
    }
  }
  &__link.handheld__link{
    @include susy-media( $window-mid){
      display: block;
      margin-top: 11px;
      text-transform: none;
      text-decoration: underline;
      color: $c-action;
    }
  }
}

.native-store{
  @include susy-media( $window-mid ){
    margin-top: 14px;
    overflow: hidden;
  }

  &__link{
    @include susy-media( $window-mid ){
      display: block;
    }

    &_google{
      @include susy-media( $window-mid ){
        float: left;
      }
    }

    &_apple{
      @include susy-media( $window-mid ){
        float: right;
      }
    }
  }
}



.contact-group{
  padding: 15px 0;

  &__label{
    display: block;
    font-weight: 700;

    &_inline{
    }
  }
}

.footer-line{
  border-top: 1px solid $c-light;
  @include susy-media( $window-mid){
    border-top-color: #323232;

  }

  &__text{
    height: 67px;
    line-height: 63px;
    display: block;
    font-size: 12px;
    text-align: center;
    @include susy-media($window-lg){
      text-align: left;
    }

    &_raduga{
      background-color: #1e1e1e;
      @include susy-media( $window-mid){
        background-color: transparent;
      }
      @include susy-media( $window-lg ){
        text-align: right;
      }
    }
  }

  .footer-line__link{
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
  }

  .footer-line__icon{
    vertical-align: middle;
    display: inline-block;
    height: 30px;
    width: 30px;
    background: url(../images/icon-raduga.png) no-repeat;
    margin-right: 20px;
  }
}

object{
  width: 100%;
  height: 100%;
}

