// Page service
// ------------

.page-service{
  .main-content{
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-content__image_xs{
    margin: 0 -20px;
    @include susy-media($window-mid){
      display: none;
    }
  }

  .main-content__image{
    display: none;
    @include susy-media($window-mid){
      display: block;
    }
  }
}

.b-price-list{
  padding-top: 20px;
  ul{
    padding: 0;
  }

  &__header{
    display: none;
  }

  &__title{
    display: block;
    font-family: $f-serif;
    text-align: center;
  }

  @include susy-media($window-mid){
    padding-top: 40px;
  }
}

.b-price-group{
  margin: 0 -20px;
  padding: 3px 20px;
  font-family: $f-main;
  list-style-type: none;

  &__title{
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: #323232;
  }

  &__text{
    display: block;
  }

  &__price{
    display: block;
    color: $c-action;

      // &:after{
      //   content: 'o';
      //   font-family: 'ASL Ruble';

      // }
    }
    &:nth-child(2n-1){
      background-color: #f3f3f3;
    }
  }
  @include susy-media($window-mid){

    .b-price-group{
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row nowrap;

      &__head-title,
      &__title{
        width: 25%;
      }

      &__head-text,
      &__text{
        width: 55%;
      }

      &__head-price,
      &__price{
        width: 20%;
      }

      &__head-title,
      &__head-price,
      &__head-text,
      &__title,
      &__price,
      &__text{
        padding: 10px 40px 10px 40px;
        display: flex;
        align-items: center;
        &:not(:last-child){
          border-right: 1px solid #646464;
        }
      }

      .b-price-group__title{
        font-weight: 400;
        font-size: 14px;
      }

      &__title,
      &__text,
      &__price{
        color: #646464;
      }
    }

    .b-price-list__header.b-price-list__header{
      display: flex;
      background-color: $c-grey-text;
      color: $c-light;
    }


  }

.main-content__actions{
  padding-top: 50px;
  text-align: center;
}