.win-xs {
  .main-sidebar{
    display: block;
  }
  .header-phone-block{
    display: block;
  }
}

.js-darken{
  background-color: #dcdcdc !important;
}

.header-phone-block,
.main-sidebar{
  @include susy-media( $window-mid){
    display: none !important;
  }
}

.main-sidebar{
  position: relative;
  padding: 0 20px 10px;
  z-index:  10;
  left: 0;
  right: 0;
  background-color: #333;

  input[type=text]{
    background-color: $c-light;
    height: 42px;
    line-height: 40px;
    border-radius: 21px;
    color: $c-main;
    padding-left: 25px;
    padding-right: 40px;
    font-size: 16px;
  }

  input[type=submit]{
    height: 15px;
    width: 15px;
    min-width: auto;
    padding: 0;
    position: absolute;
    top: 29px;
    right: 19px;
  }

  .search__button{
    background: url('../images/svg/icon-search.svg') no-repeat;
    background-size: cover;
  }

  form{
    position: relative;
    padding: 5px 0;
  }

  .menu{

    &__item{
      display: block;
    }

    &__link{
      display: block;
      font-size: 16px;
      padding: 17px 0;
      border-top: 1px solid #2e2e2e;
      &:first-child{
        border-top: none;
      }
    }
  }
}

.header-phone-block{
  position: relative;
  left: 0;
  right: 0;
  background-color: #333;
  z-index: 50;

  &__link{
    display: block;
    padding: 15px 20px;
    text-align: justify;
    text-align-last: justify;
    border-top: 1px solid #2e2e2e;
    font-size: 0;
    color: $c-light;

    &:first-child{
      border-top: none;
    }
  }

  &__text{
    font-size: 14px;
    display: inline-block;
    text-align: left;
    text-align-last: left;
    width: 70%;
  }

  &__phone{
    font-size: 14px;
    width: 30%;
    text-align: right;
    text-align-last: right;
    display: inline-block;
  }
}


// SIDEBAR MENU
// ------------

.main-sidebar{
  z-index: 50;
  input[type=text]{
    max-width: 100%;
  }
}


.sidebar-menu{
  position: relative;

  .menu{
    &__item{
      font-size: 14px;
    }

    &__link{
      display: block;
      color: $c-light;
    }
  }
}